/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import { invert } from "polished";
import styled from "styled-components";
import chroma from "chroma-js";
import { useMap, useMapEvent, GeoJSON } from "react-leaflet";
// import { FeatureLayer } from "react-esri-leaflet";
import Spinner from "react-bootstrap/Spinner";
import leafletPip from "@mapbox/leaflet-pip";
import { filterables, defaultMetric, layerClasses } from "../constants";
import arcgisPbfDecode from "arcgis-pbf-parser";
import ctData from "../data/ct_data.json";
import muniData from "../data/muni_data.json";
import stateData from "../data/state_data.json";

import {
  useSelections,
  useSelectionsDispatch,
} from "../context/SelectionsContext.js";

const LoadingOverlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(200, 200, 200, 0.5);
  z-index: 798;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: calc(50% - 75px);
  right: calc(50% - 32.5px);
  height: 75px;
  width: 75px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.2);
`;

const LoadingIndicator = styled(Spinner)``;

const geobuf = require("geobuf");
const Pbf = require("pbf");
// const utf8decoder = new TextDecoder();
// const utf8encoder = new TextEncoder();

const getSimplifyFactor = (zoom = 8) => {
  // This method should give us some level of simplification that gives a minor performance boost while not losing important detail for the given zoom level
  const maxZoom = 14;
  return 1 - (zoom / maxZoom) ** 0.25;
};

const getPrecision = (zoom = 8) => {
  // This method should give us some level of precision that gives a minor performance boost while not losing important detail for the given zoom level
  const maxZoom = 14;
  const maxPrecision = 8; // decimal points of precision
  return Math.ceil((zoom / maxZoom) * maxPrecision);
};

const clearCache = () => {
  localStorage.removeItem("appCache-ctPolygons");
  localStorage.removeItem("appCache-muniPolygons");
};

const setUpdateTimestamp = () => {
  const cachedUpdate = localStorage.getItem("appCache-updateTimestamp");
  // TODO: Check for new version instead of updating every day
  if (
    cachedUpdate == null ||
    (Date.now() - cachedUpdate) / 1000 / 60 / 60 / 24 >= 1 // Refresh once a day
    // (Date.now() - cachedUpdate) / 1000 / 60 >= 15
  ) {
    clearCache();
    localStorage.setItem("appCache-updateTimestamp", Date.now());
  }
};

const fetchCTPolygons = async (dispatch, token) => {
  let polygons = null;
  const cachedPolygons = localStorage.getItem("appCache-ctPolygons");
  if (cachedPolygons != null) {
    // Load from cache if we already have it
    polygons = JSON.parse(cachedPolygons);
  } else {
    // Cache miss: load from server
    polygons = {
      type: "FeatureCollection",
      crs: { type: "name", properties: { name: "EPSG:4326" } },
      features: [],
    };
    const polygonCount = 1614;
    const url = `https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/simplified_ct_polygons/FeatureServer/0/query?returnGeometry=true&outSR=4326&outFields=geoid&inSR=4326&f=pbf&token=${token}`;
    let polygonsList = [];
    const chunkSize = Math.ceil(polygonCount / 3);
    const chunks = [...Array(Math.ceil(polygonCount / chunkSize)).keys()].map(
      (n) => n * chunkSize,
    );
    const parts = await Promise.all(
      chunks.map((c) =>
        fetch(`${url}&where=ObjectId>${c} and ObjectId<=${c + chunkSize}`, {
          cache: "force-cache",
        }),
      ),
    );
    const buffers = await Promise.all(parts.map((part) => part.arrayBuffer()));
    polygonsList = buffers.map(
      (buff) => arcgisPbfDecode(new Uint8Array(buff)).featureCollection,
    );
    polygons.features = polygonsList.reduce((acc, v, i) => {
      return acc.concat(v.features);
    }, []);

    // Cache whole thing in localstorage
    /*
    console.time("geobuf");
    const buffer = geobuf.encode(polygons, new Pbf());
    // localStorage.setItem("appCache-ctPolygons-geobuf", buffer.toString());
    localStorage.setItem(
      "appCache-ctPolygons-geobuf",
      utf8decoder.decode(buffer),
    );
    console.timeEnd("geobuf");

    console.time("geobuf-read");
    const decodedGeoJSON = geobuf.decode(
      new Pbf(
        utf8encoder.encode(localStorage.getItem("appCache-ctPolygons-geobuf")),
      ),
    );
    console.timeEnd("geobuf-read");
    localStorage.removeItem("appCache-ctPolygons-geobuf");
    */

    localStorage.setItem("appCache-ctPolygons", JSON.stringify(polygons));
  }

  dispatch({
    type: "SET_CT_POLYGONS",
    payload: polygons,
  });
};

const fetchCTData = async (dispatch, token) => {
  let features = ctData;
  if (features == null) {
    features = [];
    const cachedData = localStorage.getItem("appCache-ctData");
    if (cachedData) {
      // Load from cache if we already have it
      features = JSON.parse(cachedData);
    } else {
      const url = `https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/updated_ct_polygons_agg/FeatureServer/0/query?returnGeometry=false&outSR=4326&outFields=*&inSR=4326&f=geojson&token=${token}`;
      const rowCount = 1614;
      let partsList = [];
      const chunkSize = 5;
      const chunks = [...Array(Math.ceil(rowCount / chunkSize)).keys()].map(
        (n) => n * chunkSize,
      );
      const parts = await Promise.all(
        chunks.map((c) =>
          fetch(`${url}&where=ObjectId>${c} and ObjectId<=${c + chunkSize}`, {
            headers: {
              Accept: "application/json",
            },
          }),
        ),
      );
      partsList = await Promise.all(parts.map((part) => part.json()));

      features = partsList.reduce((acc, v, i) => {
        return acc.concat(v.features);
      }, []);
      localStorage.setItem(`appCache-ctData`, JSON.stringify(features));
    }
  }
  dispatch({
    type: "SET_CT_DATA",
    payload: features,
  });
  const filterLimits = {};
  const metricData = {};
  filterables.forEach((f) => {
    const layerData = pullFeatureData(
      f,
      features,
      // Object.values(e.target["_layers"]),
    );
    filterLimits[f] = layerData.limits;
    metricData[f] = { values: layerData.values };
  });
  const layerData = pullFeatureData(
    "li_inv_p",
    features,
    // Object.values(e.target["_layers"]),
  );
  filterLimits["li_inv_p"] = layerData.limits;
  metricData["li_inv_p"] = { values: layerData.values };
  dispatch({
    type: "SET_FILTER_LIMITS",
    payload: filterLimits,
  });
  dispatch({
    type: "SET_METRIC_DATA",
    payload: metricData,
  });
  let classes = layerClasses[defaultMetric].ct;
  dispatch({
    type: "SET_COLORS",
    payload: {
      metric: defaultMetric,
      colors: {
        classes,
        domain: chroma.limits(metricData[defaultMetric].values, "l", 4),
      },
    },
  });
};

const fetchMuniPolygons = async (dispatch, token) => {
  let polygons = null;
  const cachedPolygons = localStorage.getItem("appCache-muniPolygons");
  if (cachedPolygons != null) {
    // Load from cache if we already have it
    polygons = JSON.parse(cachedPolygons);
  } else {
    // Cache miss: load from server
    polygons = {
      type: "FeatureCollection",
      crs: { type: "name", properties: { name: "EPSG:4326" } },
      features: [],
    };
    const polygonCount = 351;
    // const url = `https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/simplified_muni_polygons/FeatureServer/0/query?returnGeometry=true&outSR=4326&outFields=municipal&inSR=4326&f=pbf&token=${token}`;
    const url = `https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/simplified_muni_polygons_2/FeatureServer/0/query?returnGeometry=true&outSR=4326&outFields=muni_id&f=pbf&token=${token}`;
    // const url = `https://services1.arcgis.com/hGdibHYSPO59RG1h/ArcGIS/rest/services/Massachusetts_Municipalities_Hosted/FeatureServer/0/query?returnGeometry=true&outSR=4326&outFields=town_id&f=pbf&geometryPrecision=1`;
    let polygonsList = [];
    const chunkSize = Math.ceil(polygonCount / 3);
    const chunks = [...Array(Math.ceil(polygonCount / chunkSize)).keys()].map(
      (n) => n * chunkSize,
    );
    const parts = await Promise.all(
      chunks.map((c) =>
        fetch(`${url}&where=ObjectId>${c} and ObjectId<=${c + chunkSize}`, {
          cache: "force-cache",
        }),
      ),
    );
    const buffers = await Promise.all(parts.map((part) => part.arrayBuffer()));
    polygonsList = buffers.map(
      (buff) => arcgisPbfDecode(new Uint8Array(buff)).featureCollection,
    );
    polygons.features = polygonsList.reduce((acc, v, i) => {
      return acc.concat(v.features);
    }, []);

    // Cache whole thing in localstorage
    localStorage.setItem("appCache-muniPolygons", JSON.stringify(polygons));
  }
  dispatch({
    type: "SET_MUNI_POLYGONS",
    payload: polygons,
  });
};

const fetchMuniData = async (dispatch, token) => {
  let features = muniData;
  if (features == null) {
    let features = [];
    const cachedData = localStorage.getItem("appCache-muniData");
    if (cachedData) {
      // Load from cache if we already have it
      features = JSON.parse(cachedData);
    } else {
      const url = `https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/updated_muni_polygons_agg/FeatureServer/0/query?returnGeometry=false&outSR=4326&outFields=*&inSR=4326&f=geojson&token=${token}`;
      const rowCount = 352;
      let partsList = [];
      const chunkSize = 5;
      const chunks = [...Array(Math.ceil(rowCount / chunkSize)).keys()].map(
        (n) => n * chunkSize,
      );
      const parts = await Promise.all(
        chunks.map((c) =>
          fetch(`${url}&where=ObjectId>${c} and ObjectId<=${c + chunkSize}`, {
            headers: {
              Accept: "application/json",
            },
          }),
        ),
      );
      partsList = await Promise.all(parts.map((part) => part.json()));

      features = partsList.reduce((acc, v, i) => {
        return acc.concat(v.features);
      }, []);
      localStorage.setItem(`appCache-muniData`, JSON.stringify(features));
    }
  }
  dispatch({
    type: "SET_MUNI_DATA",
    payload: features,
  });
  const filterLimits = {};
  const metricData = {};
  filterables.forEach((f) => {
    const muniKey = `muni_${f}`;
    const layerData = pullFeatureData(
      muniKey,
      features,
      // Object.values(e.target["_layers"]),
    );
    filterLimits[muniKey] = layerData.limits;
    metricData[muniKey] = { values: layerData.values };
  });
  const layerData = pullFeatureData(
    "muni_li_inv_p",
    features,
    // Object.values(e.target["_layers"]),
  );
  filterLimits["muni_li_inv_p"] = layerData.limits;
  metricData["muni_li_inv_p"] = { values: layerData.values };

  dispatch({
    type: "SET_MUNI_FILTER_LIMITS",
    payload: filterLimits,
  });
  dispatch({
    type: "SET_MUNI_METRIC_DATA",
    payload: metricData,
  });
};

const fetchStateData = async (dispatch, token) => {
  let features = stateData;
  dispatch({
    type: "SET_STATE_DATA",
    payload: features,
  });
};

/*
const fetchMuniPolygons = async (dispatch, token) => {
  const url = `https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/updated_muni_polygons_agg/FeatureServer/0/query?returnGeometry=true&where=1%3D1&outSR=4326&outFields=municipal&inSR=4326&f=geojson&token=${token}`;
  const response = await fetch(url, {
    method: "GET",
  });
  const polygons = await response.json();
  dispatch({
    type: "SET_MUNI_POLYGONS",
    payload: polygons,
  });
};

const fetchMuniData = async (dispatch, token) => {
  const url = `https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/updated_muni_polygons_agg/FeatureServer/0/query?returnGeometry=false&where=1%3D1&outSR=4326&outFields=*&inSR=4326&f=geojson&token=${token}`;
  const response = await fetch(url, {
    method: "GET",
  });
  const data = await response.json();
  dispatch({
    type: "SET_MUNI_DATA",
    payload: data,
  });
};
*/

const fetchMapData = (dispatch, token, setLoading) => {
  setUpdateTimestamp();
  fetchCTPolygons(dispatch, token);
  fetchCTData(dispatch, token);
  fetchMuniPolygons(dispatch, token);
  fetchMuniData(dispatch, token);
  fetchStateData(dispatch, token);
  setLoading(true);
};

const authenticateEsri = async (client_id, client_secret, expiration) => {
  const authservice = "https://www.arcgis.com/sharing/rest/oauth2/token";
  const url = `${authservice}?client_id=${client_id}&client_secret=${client_secret}&grant_type=client_credentials&expiration=${expiration}`;

  let token;

  await fetch(url, {
    method: "POST",
  })
    .then((res) => res.json())
    .then((res) => {
      token = res.access_token;
    })
    .catch((error) => {
      console.error(error);
    });

  return token;
};

const pullLayerData = (field, layers) => {
  const allValues = [];
  let max = Number.NEGATIVE_INFINITY;
  let min = Number.POSITIVE_INFINITY;
  for (let layer of layers) {
    let value = layer.feature.properties[field];
    if (value == null) {
      continue;
      // value = 0;
    }
    allValues.push(value);
    if (value < min) {
      min = value;
    }
    if (value > max) {
      max = value;
    }
  }
  return { limits: { min, max }, values: allValues };
};

const pullFeatureData = (field, features) => {
  const allValues = [];
  let max = Number.NEGATIVE_INFINITY;
  let min = Number.POSITIVE_INFINITY;
  for (let feature of features) {
    let value = feature.properties[field];
    if (value == null) {
      continue;
      // value = 0;
    }
    allValues.push(value);
    if (value < min) {
      min = value;
    }
    if (value > max) {
      max = value;
    }
  }
  return { limits: { min, max }, values: allValues };
};

const defaultStyle = {
  // fillColor: defaultScale(null),
  fillColor: "#222222",
  color: "black",
  weight: 0.8, // zoom (default: 8) / 10
  fillOpacity: 0.9,
  opacity: 0.5,
};

const Layers = () => {
  const {
    muni: selectedMuni,
    tract: selectedTract,
    metric: selectedMetric,
    metricData,
    filters,
    colors: allColors,
    map,
  } = useSelections();

  const colors = allColors.byMetric[selectedMetric];

  const [loading, setLoading] = useState(null);

  const [colorScale, setColorScale] = useState(null);
  const dispatch = useSelectionsDispatch();
  const mapRef = useMap();
  const zoom = useRef(mapRef.getZoom());
  useMapEvent('zoom', () => {
    zoom.current = mapRef.getZoom();
  })
  const [token, setToken] = useState(null);
  const [tokenTime, setTokenTime] = useState(0);

  useEffect(() => {
    if (token != null && loading == null) {
      fetchMapData(dispatch, token, setLoading);
    }
  }, [dispatch, loading, token, setLoading]);

  useEffect(() => {
    if (
      loading &&
      map.ctData != null &&
      map.ctPolygons != null &&
      map.muniData != null &&
      map.muniPolygons != null
    ) {
      setLoading(false);
    }
  }, [loading, setLoading, map]);

  useEffect(() => {
    const colorMetric =
      map.selectedLayer === "Municipalities"
        ? `muni_${selectedMetric}`
        : selectedMetric;
    if (metricData == null || metricData[colorMetric] == null) {
      return;
    }
    if (
      (map.ctData && map.ctPolygons && map.selectedLayer === "Census Tracts") ||
      (map.muniData &&
        map.muniPolygons &&
        map.selectedLayer === "Municipalities")
    ) {
      const min =
        map.selectedLayer === "Census Tracts"
          ? filters[selectedMetric].min
          : filters[selectedMetric].muniMin;
      const max =
        map.selectedLayer === "Census Tracts"
          ? filters[selectedMetric].max
          : filters[selectedMetric].muniMax;
      if (colors.continuous) {
        setColorScale(() =>
          chroma
            .scale(colors.scale)
            .mode(colors.mode)
            .domain([min, max])
            .correctLightness(),
        );
      } else {
        const geography = map.selectedLayer === "Municipalities"? "muni": "ct";
        let classes = layerClasses[selectedMetric]? layerClasses[selectedMetric][geography] : chroma.limits(
          metricData[colorMetric].values,
          colors.colorAssignmentMode,
          colors.classCount,
        );
        setColorScale(() =>
          chroma
            .scale(colors.scale)
            // .mode(colors.mode)
            .classes(classes)
            .correctLightness(),
        );
      }
    }
  }, [
    allColors,
    colors,
    selectedMetric,
    metricData,
    filters,
    map.selectedLayer,
    map.muniPolygons,
    map.muniData,
    map.ctData,
    map.ctPolygons,
  ]);

  useEffect(() => {
    if (!token || Date.now() - tokenTime > 3600) {
      const getToken = async () => {
        const token = await authenticateEsri(
          process.env.REACT_APP_AGOL_CLIENT_ID,
          process.env.REACT_APP_AGOL_CLIENT_SECRET,
        );
        setToken(token);
        setTokenTime(Date.now());
      };
      getToken();
    }
  }, [token, tokenTime]);

  if (token == null || colorScale == null) {
    return (
      <LoadingOverlay>
        <LoadingContainer>
          <LoadingIndicator animation="border" role="status">
            <span style={{ display: "none" }}>Loading...</span>
          </LoadingIndicator>
        </LoadingContainer>
      </LoadingOverlay>
    );
  }

  return (
    <>
      {/*
            <GeoJSON
              id="ghost-ct-layer"
              data={map.ctPolygons}
              pane="overlayPane"
              style={{
                fillOpacity: 0.0,
                opacity: 0.0,
              }}
            />
            <GeoJSON
              id="muni-layer"
              data={map.muniPolygons}
              url="https://services.arcgis.com/c5WwApDsDjRhIVkH/arcgis/rest/services/updated_muni_polygons_agg/FeatureServer/0"
              pane="overlayPane"
              eventHandlers={{
                click: (e) => {
                  const pil = leafletPip.pointInLayer(
                    [e.latlng.lng, e.latlng.lat],
                    mapRef,
                  );
                  const [
                    { feature: feature1 = null } = {},
                    { feature: feature2 = null } = {},
                  ] = pil;
                  let muni = null;
                  if (feature1 && feature1.properties.municipal != null) {
                    muni = feature1;
                  } else if (
                    feature2 &&
                    feature2.properties.municipal != null
                  ) {
                    muni = feature2;
                  }
                  let tract = null;
                  if (feature1 && feature1.properties.geoid != null) {
                    tract = feature1;
                  } else if (feature2 && feature2.properties.geoid != null) {
                    tract = feature2;
                  }
                  dispatch({
                    type: "SET_LAT_LONG",
                    payload: e.latlng,
                  });
                  dispatch({
                    type: "SET_MUNI",
                    payload: muni ? muni.properties : null,
                  });
                  dispatch({
                    type: "SET_TRACT",
                    payload: tract ? tract.properties : null,
                  });
                },
              }}
              style={(f) => {
                let fillColor = "#DEDEDE";
                const muniMetric = `muni_${metric}`;
                const muniMax = 1000; // FIXME: calculate this for the muni layer, eventually
                if (
                  f.properties[muniMetric] != null &&
                  f.properties[muniMetric] < 1
                ) {
                  // TODO: separate treatment for outliers
                  const satRate = f.properties[muniMetric] / muniMax;
                  fillColor = tint(
                    0.25,
                    adjustHue(
                      -180 * (satRate ** 0.79) ** 0.79,
                      // darken(satRate ** 0.42, "rgb(255, 99, 71)"),
                      saturate((satRate ** 0.3) ** 0.3, "rgb(34, 139, 34)"),
                    ),
                  );
                }
                const contrast = adjustHue(-180, fillColor);
                const selected =
                  selectedMuni &&
                  f.properties.municipal === selectedMuni.municipal;
                const polyStyle = {
                  color: selected ? contrast : "black",
                  fillColor: fillColor,
                  weight: selected ? zoom * 0.75 : zoom / 10,
                  fillOpacity: 0.7,
                  opacity: selected ? 1 : 0.5,
                };
                return polyStyle;
              }}
            />
            */}
      {map.muniPolygons && map.muniData && (
        <GeoJSON
          id="muni-layer"
          data={map.muniPolygons}
          style={({ properties: { muni_id } }) => {
            if (map.selectedLayer === "Municipalities") {
              const f = map.muniData[muni_id];
              const muniMetric = `muni_${selectedMetric}`; // Do this since municipal metrics have a prefix
              let satRate = f[muniMetric];
              /*
              if (selectedMetric.endsWith("_p")) {
                satRate =
                  (f[muniMetric] - filters[selectedMetric].min) /
                  (filters[selectedMetric].max - filters[selectedMetric].min);
              }
              */
              let filtered = false;
              for (let [filterMetric, filter] of Object.entries(filters)) {
                if (
                  filter.enabled &&
                  f[`muni_${filterMetric}`] <= filter.value
                ) {
                  filtered = true;
                  break;
                }
              }
              const polyStyle = {
                ...defaultStyle,
                fillColor: filtered ? "#FFF" : colorScale(satRate).hex(),
                fillOpacity: filtered ? 0.25 : 0.9,
              };
              const selected = selectedMuni && muni_id === selectedMuni.muni_id;
              if (selected) {
                const contrast = invert(polyStyle.fillColor);
                polyStyle.color = contrast;
                polyStyle.weight = zoom.current * 0.5;
                polyStyle.opacity = 1;
              }
              return polyStyle;
            } else {
              return {
                fillOpacity: 0.0,
                opacity: 0.0,
              };
            }
          }}
          eventHandlers={{
            click: (e) => {
              const pil = leafletPip.pointInLayer(
                [e.latlng.lng, e.latlng.lat],
                mapRef,
              );
              const [
                { feature: feature1 = null } = {},
                { feature: feature2 = null } = {},
              ] = pil;
              let muni = null;
              if (feature1 && feature1.properties.muni_id != null) {
                muni = feature1;
              } else if (feature2 && feature2.properties.muni_id != null) {
                muni = feature2;
              }
              let tract = null;
              if (feature1 && feature1.properties.geoid != null) {
                tract = feature1;
              } else if (feature2 && feature2.properties.geoid != null) {
                tract = feature2;
              }
              dispatch({
                type: "SET_LAT_LONG",
                payload: e.latlng,
              });
              dispatch({
                type: "SET_STATE",
                payload: map.stateData["MA"],
              });
              dispatch({
                type: "SET_MUNI",
                payload: muni ? map.muniData[muni.properties.muni_id] : null,
              });
              dispatch({
                type: "SET_TRACT",
                payload: tract ? map.ctData[tract.properties.geoid] : null,
              });
            },
          }}
        />
      )}
      {map.ctPolygons && map.ctData && (
        <GeoJSON
          id="ct-layer"
          data={map.ctPolygons}
          style={({ properties: { geoid } }) => {
            if (map.selectedLayer === "Census Tracts") {
              const f = map.ctData[geoid];
              if (f == null) {
                console.log("Didn't find ct with geoid", geoid);
                return defaultStyle;
              }
              let satRate = f[selectedMetric];
              /*
              if (selectedMetric.endsWith("_p")) {
                satRate =
                  (f[selectedMetric] - filters[selectedMetric].min) /
                  (filters[selectedMetric].max - filters[selectedMetric].min);
              }
              */
              let filtered = false;
              for (let [filterMetric, filter] of Object.entries(filters)) {
                if (filter.enabled && f[filterMetric] <= filter.value) {
                  filtered = true;
                  break;
                }
              }
              const polyStyle = {
                ...defaultStyle,
                fillColor: filtered ? "#FFF" : colorScale(satRate).hex(),
                fillOpacity: filtered ? 0.25 : 0.9,
              };
              const selected =
                selectedTract && Number(geoid) === selectedTract.geoid;
              if (selected) {
                const contrast = invert(polyStyle.fillColor);
                polyStyle.color = contrast;
                polyStyle.weight = zoom.current * 0.5;
                polyStyle.opacity = 1;
              }
              return polyStyle;
            } else {
              return {
                fillOpacity: 0.0,
                opacity: 0.0,
              };
            }
          }}
          eventHandlers={{
            load: (e) => {
              Object.values(e.target["_layers"]).forEach((layer) =>
                layer.bringToFront(),
              );
            },
            click: (e) => {
              const pil = leafletPip.pointInLayer(
                [e.latlng.lng, e.latlng.lat],
                mapRef,
              );
              const [
                { feature: feature1 = null } = {},
                { feature: feature2 = null } = {},
              ] = pil;
              let muni = null;
              if (feature1 && feature1.properties.muni_id != null) {
                muni = feature1;
              } else if (feature2 && feature2.properties.muni_id != null) {
                muni = feature2;
              }
              let tract = null;
              if (feature1 && feature1.properties.geoid != null) {
                tract = feature1;
              } else if (feature2 && feature2.properties.geoid != null) {
                tract = feature2;
              }
              dispatch({
                type: "SET_LAT_LONG",
                payload: e.latlng,
              });
              dispatch({
                type: "SET_STATE",
                payload: map.stateData["MA"],
              });
              dispatch({
                type: "SET_MUNI",
                payload: muni ? map.muniData[muni.properties.muni_id] : null,
              });
              dispatch({
                type: "SET_TRACT",
                payload: tract ? map.ctData[tract.properties.geoid] : null,
              });
            },
          }}
        />
      )}
      {map.muniPolygons &&  (
        <GeoJSON
          data={map.muniPolygons}
          className={"pass-through"}
          style={{
            fillOpacity: 0.0,
            color: "#333",
            weight: 2,
            opacity: 0.5,
          }}
        />
      )}
    </>
  );
};

export default Layers;
