import React, { useState } from "react";
import { useTheme } from "styled-components";
import { useSelections } from "../context/SelectionsContext.js";
import styled from "styled-components";
import Table from "react-bootstrap/Table";
import { ArrowBarLeft, ArrowBarRight } from "react-bootstrap-icons";

import { HousingBarChart } from "./HousingBarChart";
import { fastTitleCase, displayFormat } from "../util";
import { displayLabels, displayables } from "../constants";

const Sidebar = styled.section`
  flex-direction: column;
  min-width: 550px;
  max-width: 550px;
  width: 550px;
  padding: 1rem;
  border-right: 2px solid ${(props) => props.theme.dark};
  left: ${(props) => (props.open ? "0px" : "-550px")};

  position: fixed;
  z-index: 2000;
  background-color: ${(props) => props.theme.offwhite};
  min-height: 100%;
  height: 100%;

  table {
    width: initial;
    th {
      white-space: nowrap;
      padding: 12.5px 10px 10px;
      text-align: center;
      background-color: ${(props) => props.theme.mid};
      color: white;
    }
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4) {
      text-align: end;
      vertical-align: middle;
      padding-right: 7.5px;
    }
  }
`;

const BarTab = styled.button`
  flex: 1;
  width: 35px;

  background-color: ${(props) => props.theme.offwhite};

  position: absolute;
  right: -35px;
  top: calc(50% - 17.5px - 2.5rem);
  z-index: 999;
  border-width: medium 2px medium medium;
  border-style: solid solid solid none;
  border-color: currentcolor rgb(0, 37, 110) currentcolor currentcolor;
  height: 75px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-left: none;
`;

const SidebarHeader = styled.h2`
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0px;
`;

const SidebarSubheader = styled.h3`
  font-size: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0px;
`;

const ScrollContainer = styled.div`
  height: 90vh;
  overflow-y: scroll;
`;

export const DataSidebar = () => {
  const theme = useTheme();
  const { state, muni, tract } = useSelections();
  const [hidden, setHidden] = useState(false);
  const sidebarOpen = muni != null && !hidden;

  return (
    <Sidebar open={sidebarOpen}>
      {state && muni && tract && (
        <>
          <BarTab
            onClick={(e) => {
              setHidden(!hidden);
            }}
          >
            {hidden ? (
              <ArrowBarRight color={theme.dark} size={20} />
            ) : (
              <ArrowBarLeft color={theme.dark} size={20} />
            )}
          </BarTab>
        <ScrollContainer>
          <SidebarHeader>{muni ? muni.municipal : "N/A"}</SidebarHeader>
          <SidebarSubheader>
            {`${
              tract ? tract.geoid : "N/A"
            } (2020 Census Tract ID)`}
          </SidebarSubheader>
          <Table size="sm" striped={true} bordered={true} hover={true}>
            <thead>
              <tr>
                <th>Measurement</th>
                <th>Census Tract</th>
                <th>Municipality</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              {displayables.map((attr) => (
                <tr key={attr}>
                  <td>{displayLabels[attr]}</td>
                  <td>{displayFormat(attr, tract[`${attr}`])}</td>
                  <td>{displayFormat(attr, muni[`muni_${attr}`])}</td>
                  <td>{displayFormat(attr, state[`${attr}`])}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <HousingBarChart tract={tract} />
        </ScrollContainer>
        </>
      )}
    </Sidebar>
  );
};

export default DataSidebar;
