import { useState } from 'react';

import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Map from "../components/Map";
import {
  useSelections,
  useSelectionsDispatch,
} from "../context/SelectionsContext.js";

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
	
`;

const InfoModal = styled(Modal)`
  div.h4 {
		padding-top: 0.375rem;
	}

  label {
		padding-top: 0.125rem;
	}

	button {
		padding-top: 0.5rem;
	}

  a {
    text-decoration: underline;
    color: #2364bb;
  }

  a:visited {
    text-decoration: underline;
    color: #8c5371;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
	align-items: center;
	justify-content: space-between;
`

export const MapPage = () => {
  const { hideModal } = useSelections();
	const [ checked, setChecked ] = useState(hideModal);
  const dispatch = useSelectionsDispatch();
	// const set
  return (
    <MapWrapper>
      <InfoModal show={!hideModal} onHide={() =>
				dispatch({
					type: "SET_HIDE_MODAL",
					payload: true,
				})
			}>
        <Modal.Header closeButton>
          <Modal.Title>Homes for Profit - Overview</Modal.Title>
        </Modal.Header>
        <Modal.Body>This map represents data from the <a href="/report">Homes for Profit report</a>. Select a geography on the map to see the data. You can use the controls on the right side to choose which data to visualize, apply filters, or show different geographies.<br /><br />The full report can be found on the <a href="/report">Report</a> page, and you can navigate there using the link in the header. A summary of the report is also available on the <a href="executive-summary">Executive Summary</a> page.<br /><br />For more details about how these data were generated and the organization that created the report, visit the <a href="/about">About</a> page.<br /><br /><span style={{fontStyle: "italic"}}>Update: As of April 11, 2024, the data on this map now includes transactions from 2023.</span></Modal.Body>
        <ModalFooter>
          <Form.Check checked={checked} label="Hide this message in the future" variant="primary" onChange={(e) => {
						setChecked(!checked);
						localStorage.setItem("appCache-hideModal", JSON.stringify(e.target.checked));
					}}/>
          <Button variant="primary" onClick={() => 
						dispatch({
							type: "SET_HIDE_MODAL",
							payload: true,
						})}>
            Continue to the Map
          </Button>
        </ModalFooter>
      </InfoModal>
      <Map />
    </MapWrapper>
  );
};

export default MapPage;
