import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import MapPage from "./pages/MapPage";
import ReportPage from "./pages/ReportPage";
import AboutPage from "./pages/AboutPage";
import ExecutiveSummaryPage from "./pages/ExecutiveSummary";
import smoothscroll from "smoothscroll-polyfill";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import reportWebVitals from "./reportWebVitals";

smoothscroll.polyfill();

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <MapPage />,
      },
      {
        path: "map",
        element: <MapPage />,
      },
      {
        path: "executive-summary",
        element: <ExecutiveSummaryPage />,
      },
      {
        path: "report",
        element: <ReportPage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
