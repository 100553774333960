import React from "react";
import styled from "styled-components";

import { useSelections } from "../context/SelectionsContext.js";
import { LayersPanel } from "./LayersPanel";
import { MetricsPanel } from "./MetricsPanel";
import { FiltersPanel } from "./FiltersPanel";
import { StylesPanel } from "./StylesPanel";
import { InfoModalButton } from "./InfoModalButton";

// Simulating Leaflet controls
const LeavletControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  position: absolute;
  top: 10px;
  right: 0px;
  z-index: 800;

  font-size: 1rem;
  line-height: 1.5;

  margin-right: 10px;
  width: fit-content;
`;

export const Controls = () => {
  const { selectedControls } = useSelections();
  return (
    <LeavletControls>
      <LayersPanel controlsId="layers" selectedControls={selectedControls} />
      <MetricsPanel controlsId="metrics" selectedControls={selectedControls} />
      <FiltersPanel controlsId="filters" selectedControls={selectedControls} />
    {/*<StylesPanel controlsId="styles" selectedControls={selectedControls} />*/}
      <InfoModalButton controlsId="info" selectedControls={selectedControls} />
    </LeavletControls>
  );
};

export default Controls;
