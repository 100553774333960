import React, { useRef } from "react";
import {
  useSelections,
  useSelectionsDispatch,
} from "../context/SelectionsContext.js";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownHeader from "react-bootstrap/DropdownHeader";
import { Palette, X } from "react-bootstrap-icons";
import debounce from "lodash-es/debounce";

const ControlContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

const RangeControlContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ColorsControlContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

const LeavletControl = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  border-radius: 5px;
  background-color: white;
  background-clip: padding-box;
  box-shadow: none;
  max-width: ${(props) => (props.expanded ? "initial" : "fit-content")};
`;

const ControlsContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-height: 300px;
`;

const ControlsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
`;

const ControlsLogoTitle = styled.div`
  display: ${(props) => (props.expanded ? "flex" : "none")};
  flex: 1;
  gap: 1rem;
  align-items: stretch;
  justify-content: flex-start;
  svg {
    margin-top: 0.3rem;
    /* Need to correct for font height */
  }
`;

const ControlsTitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0px;
`;

const ControlsToggle = styled.button`
  flex: 1;
  width: 35px;
  max-width: 35px;
  height: 35px;
  max-height: 35px;

  /*background-color: ${(props) => props.theme.offwhite};*/
  pointer-events: auto;

  border: none;
  background-color: white;
  padding: 0px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 1rem;
  min-width: 300px;
`;

const Control = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const ControlHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
`;

const ControlLabel = styled(Form.Label)`
  margin-bottom: 0px;
  padding-top: 2.5px; /* needed to fix some issue with Calibre font vertical alignment */
`;

const RangeControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: 1rem;

  input.form-range {
    flex: 7;
  }
`;

const RangeValue = styled.div`
  width: fit-content;
`;

const RangeTerminus = styled.div`
  flex: 1;
  padding-top: 4px;
  text-align: center;
`;

const Range = styled(Form.Range)`
  pointer-events: auto;
`;

const ColorControl = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StylesPanel = ({
  icon = <Palette size={20} />,
  title = "Map Styles",
  controlsId,
}) => {
  const dispatch = useSelectionsDispatch();
  const {
    colors,
    metric,
    // colors: { scale, continuous, mode, classCount, colorAssignmentMode },
    selectedControls,
  } = useSelections();
  const expanded = selectedControls === controlsId;
  const onClose = (e) => {
    dispatch({
      type: "SET_SELECTED_CONTROLS",
      payload: null,
    });
  };

  const { scale, continuous, mode, classCount, colorAssignmentMode } =
    colors.byMetric[metric] || {};

  const onOpen = (e) => {
    dispatch({
      type: "SET_SELECTED_CONTROLS",
      payload: controlsId,
    });
  };

  const colorSpaces = ["rgb", "hsl", "lab", "lch", "lrgb"];
  const colorAssignmentModes = {
    e: {
      name: "Equidistant",
    },
    q: {
      name: "Quantile",
    },
    k: {
      name: "K-Means Clustering",
    },
    l: {
      name: "Logarithmic",
    },
  };

  const updateColor = useRef(
    debounce((i, metric, e) => {
      dispatch({
        type: `SET_INDEX_COLOR`,
        payload: { metric, i, color: e.target.value },
      });
    }, 200),
  );

  return (
    <LeavletControl
      expanded={expanded}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <ControlsContainer>
        <ControlsHeader>
          <ControlsLogoTitle expanded={expanded}>
            <ControlsTitle>{title}</ControlsTitle>
            {icon}
          </ControlsLogoTitle>
          <ControlsToggle onClick={expanded ? onClose : onOpen}>
            {expanded ? <X size={20} /> : icon}
          </ControlsToggle>
        </ControlsHeader>
        {expanded && (
          <Controls>
            <Control>
              <ControlHeader>
                <ControlLabel>Color Scale</ControlLabel>
              </ControlHeader>
              <ControlContainer>
                <Form.Check
                  type="radio"
                  id="radio-colors-continuous"
                  label="Continuous gradient"
                  checked={continuous}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_CONTINUOUS_COLORS",
                      payload: { metric, continuous: true },
                    });
                  }}
                />
                <Form.Check
                  type="radio"
                  id="radio-colors-discrete"
                  label="Discrete classes"
                  checked={!continuous}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_CONTINUOUS_COLORS",
                      payload: { metric, continuous: false },
                    });
                  }}
                />
              </ControlContainer>
            </Control>
            <Control>
              <ControlHeader>
                <ControlLabel>Colors</ControlLabel>
              </ControlHeader>
              <ColorsControlContainer>
                <ColorControl>
                  <Form.Label>Minimum value</Form.Label>
                  <Form.Control
                    type="color"
                    defaultValue={scale[0]}
                    title="Choose a color"
                    onChange={(e) => updateColor.current(0, metric, e)}
                  />
                </ColorControl>
                <ColorControl>
                  <Form.Label>Middle value</Form.Label>
                  <Form.Control
                    type="color"
                    defaultValue={scale[1]}
                    title="Choose a color"
                    onChange={(e) => updateColor.current(1, metric, e)}
                  />
                </ColorControl>
                <ColorControl>
                  <Form.Label>Maximum value</Form.Label>
                  <Form.Control
                    type="color"
                    defaultValue={scale[2]}
                    title="Choose a color"
                    onChange={(e) => updateColor.current(2, metric, e)}
                  />
                </ColorControl>
              </ColorsControlContainer>
            </Control>
            <Control>
              <ControlHeader>
                <ControlLabel>Color Mixing</ControlLabel>
              </ControlHeader>
              <ControlContainer>
                <DropdownHeader>Color Space</DropdownHeader>
                <Dropdown
                  onSelect={(value) => {
                    dispatch({
                      type: `SET_COLOR_MODE`,
                      payload: { metric, mode: value },
                    });
                  }}
                >
                  <Dropdown.Toggle size="sm">
                    {mode.toUpperCase()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {colorSpaces.map((space) => (
                      <Dropdown.Item
                        key={space}
                        eventKey={space}
                        active={space === mode}
                      >
                        {space.toUpperCase()}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </ControlContainer>
            </Control>
            {!continuous && (
              <Control>
                <ControlHeader>
                  <ControlLabel>Color Classes</ControlLabel>
                </ControlHeader>
                <ControlContainer>
                  <DropdownHeader>Color Assignment Mode</DropdownHeader>
                  <Dropdown
                    onSelect={(value) => {
                      dispatch({
                        type: `SET_COLOR_ASSIGNMENT_MODE`,
                        payload: { metric, colorAssignmentMode: value },
                      });
                    }}
                  >
                    <Dropdown.Toggle disabled={continuous} size="sm">
                      {colorAssignmentModes[colorAssignmentMode].name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(colorAssignmentModes).map((mode) => (
                        <Dropdown.Item
                          key={mode}
                          eventKey={mode}
                          active={mode === colorAssignmentMode}
                        >
                          {colorAssignmentModes[mode].name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </ControlContainer>
                <RangeControlContainer>
                  <Form.Label>Number of Color Classes</Form.Label>
                  <RangeControl>
                    <RangeTerminus>2</RangeTerminus>
                    <Range
                      disabled={continuous}
                      min={2}
                      max={10}
                      value={classCount}
                      step={1}
                      onChange={(e) => {
                        dispatch({
                          type: `SET_COLOR_CLASS_COUNT`,
                          payload: {
                            metric,
                            classCount: Number(e.target.value),
                          },
                        });
                      }}
                    />
                    <RangeTerminus>10</RangeTerminus>
                  </RangeControl>
                  <RangeValue>{classCount}</RangeValue>
                </RangeControlContainer>
              </Control>
            )}
          </Controls>
        )}
      </ControlsContainer>
    </LeavletControl>
  );
};

export default StylesPanel;
