import { mapboxBaseURL } from "./constants";

export const createTileURL = (style) => {
  const params = new URLSearchParams();
  params.set("access_token", process.env.REACT_APP_MAPBOX_TOKEN);
  const stylePath = `styles/v1/mapbox/${style}/tiles/{z}/{x}/{y}/`;
  /*
  return new URL(`${mapboxBaseURL}${stylePath}?${params}`).toString();
  */
  return `${mapboxBaseURL}${stylePath}?${params}`;
};

export const fastTitleCase = (str) => {
  let upper = true;
  let newStr = "";
  for (let i = 0, l = str.length; i < l; i++) {
    if (str[i] === " ") {
      upper = true;
      newStr += " ";
      continue;
    }
    newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
    upper = false;
  }
  return newStr;
};

export const toPercent = (value, maxDecimals = 1) => {
  return `${Number(value).toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: maxDecimals,
  })}`;
};

export const displayFormat = (attr, value, maxDecimals) => {
  if (attr.endsWith("_p")) {
    return toPercent(value, maxDecimals);
  } else {
    return ~~value;
  }
};
