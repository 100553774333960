import React, {useRef} from "react";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
// import { Footer } from "../components/Footer.tsx";
// import { theme } from "../theme";

const SummaryContainer = styled.div`
  flex: 1;
  width: 60em;
  min-width: 60em;
  max-width: 60em;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  /*overflow-y: scroll;*/

  h2 {
    font-size: 1.125rem;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
    color: #2364bb;
  }

  a:visited {
    text-decoration: underline;
    color: #8c5371;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;

  footer {
    a,
    a:visited {
      color: ${(props) => props.theme.offwhite};
      text-decoration: none;
      /*
      font-weight: bold;
      font-size: 20px;
			*/
    }
  }
`;

export const ExecutiveSummaryPage = () => {
    return (
      <PageContainer>
          <SummaryContainer>
             <object data="documents/HomesForProfitExecutiveSummary.pdf" type="application/pdf" width="100%" height="100%">
            <p><a href="documents/HomesForProfitExecutiveSummary.pdf">Homes for Profit Executive Summary</a></p>
        </object>
          </SummaryContainer>
    </PageContainer>
  );
}

export default ExecutiveSummaryPage;
