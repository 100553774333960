import React, { useEffect, useState } from "react";
import chroma from "chroma-js";
import { useSelections } from "../context/SelectionsContext.js";
import { rgba } from "polished";
import styled from "styled-components";
import { CardList, X } from "react-bootstrap-icons";

import { displayLabels, layerClasses } from "../constants";
import { displayFormat } from "../util";

const LeavletControl = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  border-radius: 5px;
  background-color: white;
  background-clip: padding-box;
  box-shadow: none;
  max-width: ${(props) => (props.expanded ? "initial" : "fit-content")};

  position: absolute;
  bottom: 27px;
  right: 55px;
  z-index: 799; /* basically same as other leaflet/leavlet controls, but let them go "over" this if needed */
`;

const ControlsContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-height: 300px;
  width: ${(props) => (props.expanded ? "700px" : "initial")};
`;

const ControlsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ControlsLogoTitle = styled.div`
  display: ${(props) => (props.expanded ? "flex" : "none")};
  flex: 1;
  gap: 1rem;
  align-items: stretch;
  justify-content: flex-start;
  svg {
    margin-top: 0.3rem;
    /* Need to correct for font height */
  }
`;

const ControlsTitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0px;
`;

const ControlsToggle = styled.button`
  flex: 1;
  width: 35px;
  max-width: 35px;
  height: 35px;
  max-height: 35px;

  /*background-color: ${(props) => props.theme.offwhite};*/
  pointer-events: auto;

  border: none;
  background-color: white;
  padding: 0px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  padding: 0rem 1rem;
  min-width: 300px;
`;

const Control = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 0.75rem;
  line-height: 1.5;
`;

const ControlHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const LegendColors = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
`;

const LegendGradient = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const GradientColors = styled.div`
  display: flex;
  width: 100%;
  height: 1.25rem;
`;

const GradientColor = styled.span`
  width: 1%;
  background-color: ${(props) => props.backgroundColor};
`;

const GradientBounds = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

const LegendColor = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
`;

const Swatch = styled.div`
  height: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  max-height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  background-color: ${(props) => rgba(props.forColor._rgb[0], props.forColor._rgb[1], props.forColor._rgb[2], 0.9) }};
  border: 1px solid black;
`;

const LegendColorLabel = styled.p`
  flex: 1;
  display: inline-block;
  margin-bottom: 0px;
  min-width: 60px;
  text-align: center;
  white-space: nowrap;
`;

export const Legend = ({
  icon = <CardList size={20} />,
  title = "Legend",
  controlsId,
}) => {
  //const theme = useTheme();
  const {
    metric: selectedMetric,
    metricData,
    filters,
    colors: allColors,
    map,
  } = useSelections();
  const colors = allColors.byMetric[selectedMetric];
  const [expanded, setExpanded] = useState(true);
  const onClose = (e) => {
    setExpanded(false);
  };

  const onOpen = (e) => {
    setExpanded(true);
  };

  const [colorScale, setColorScale] = useState(null);
  const colorMetric =
    map.selectedLayer === "Municipalities"
      ? `muni_${selectedMetric}`
      : selectedMetric;

  useEffect(() => {
    if (metricData == null || metricData[colorMetric] == null) {
      return;
    }
    if (
      (map.ctData && map.ctPolygons && map.selectedLayer === "Census Tracts") ||
      (map.muniData &&
        map.muniPolygons &&
        map.selectedLayer === "Municipalities")
    ) {
      const min =
        map.selectedLayer === "Census Tracts"
          ? filters[selectedMetric].min
          : filters[selectedMetric].muniMin;
      const max =
        map.selectedLayer === "Census Tracts"
          ? filters[selectedMetric].max
          : filters[selectedMetric].muniMax;
      if (colors.continuous) {
        setColorScale(() =>
          chroma
            .scale(colors.scale)
            .mode(colors.mode)
            .domain([min, max])
            .correctLightness(),
        );
      } else {
        const geography = map.selectedLayer === "Municipalities"? "muni": "ct";
        let classes = layerClasses[selectedMetric]? layerClasses[selectedMetric][geography] : chroma.limits(
          metricData[colorMetric].values,
          colors.colorAssignmentMode,
          colors.classCount,
        );
        setColorScale(() =>
          chroma
            .scale(colors.scale)
            // .mode(colors.mode)
            .classes(classes)
            .correctLightness(),
        );
      }
    }
  }, [
    colorMetric,
    colors,
    selectedMetric,
    metricData,
    filters,
    map.selectedLayer,
    map.muniPolygons,
    map.muniData,
    map.ctData,
    map.ctPolygons,
  ]);

  const min =
    map.selectedLayer === "Census Tracts"
      ? filters[selectedMetric].min
      : filters[selectedMetric].muniMin;
  const max =
    map.selectedLayer === "Census Tracts"
      ? filters[selectedMetric].max
      : filters[selectedMetric].muniMax;

  const gradientColors = () => {
    const step = (max - min) / 100;
    return [...Array(100).keys()].map((i) => {
      const color = colorScale(i * step).hex();
      return <GradientColor key={`${color}${i}`} backgroundColor={color} />;
    });
  };

  if (colorScale == null) {
    return <></>;
  }

  return (
    <LeavletControl
      expanded={expanded}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <ControlsContainer>
        <ControlsHeader>
          <ControlsLogoTitle expanded={expanded}>
            <ControlsTitle>{title}</ControlsTitle>
            {icon}
          </ControlsLogoTitle>
          <ControlsToggle onClick={expanded ? onClose : onOpen}>
            {expanded ? <X size={20} /> : icon}
          </ControlsToggle>
        </ControlsHeader>
        {expanded && (
          <Controls>
            <Control>
              <ControlHeader>{`${displayLabels[selectedMetric]} (${selectedMetric})`}</ControlHeader>
              {colors.continuous && (
                <LegendGradient>
                  <GradientColors>{gradientColors()}</GradientColors>
                  <GradientBounds>
                    <div>{displayFormat(selectedMetric, min, 0)}</div>
                    <div>{displayFormat(selectedMetric, max / 2, 0)}</div>
                    <div>{displayFormat(selectedMetric, max, 0)}</div>
                  </GradientBounds>
                </LegendGradient>
              )}
              {!colors.continuous &&
                typeof colorScale.classes() === "object" && (
                  <LegendColors>
                    {colorScale
                      .classes()
                      .slice(0, -1)
                      .map((c, i) => {
                        return (
                          <LegendColor key={String(c)}>
                            <Swatch size={35} forColor={colorScale(c)} />
                            <LegendColorLabel>{`${displayFormat(
                              selectedMetric,
                              c,
                              0,
                            )} - ${displayFormat(
                              selectedMetric,
                              colorScale.classes()[i + 1],
                              0,
                            )}`}</LegendColorLabel>
                          </LegendColor>
                        );
                      })}
                  </LegendColors>
                )}
            </Control>
          </Controls>
        )}
      </ControlsContainer>
    </LeavletControl>
  );
};

export default Legend;
