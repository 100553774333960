export const displayLabels = {
  // TODO: make this better
  mapMinColor: "Color range minimum",
  mapMidColor: "Color range middle",
  mapMaxColor: "Color range maximum",
  join_key: "Geographic Identifier",
  title: "Title",
  alt_title: "Alternate Title",
  tbl_table: "Table Name",
  tbl_num: "Table number from source data",
  geography: "Spatial Geography",
  descriptn: "Description",
  coverage: "Coverage",
  universe: "Universe",
  creator: "Creator/Source",
  publisher: "Publisher",
  contributr: "Contributors",
  createdate: "CreationDate",
  muni_id: "Municipal ID",
  municipal: "Municipality",
  geoid: "Census Geographic Level ID",
  logrecno: "Census Logical Record Number",
  trans_0023: "Number of transactions 2000-2023",
  trans_0221: "Number of transactions 2002-2021",
  trans_0419: "Number of transactions 2004-2019",
  inv_trans_0419: "Number of investor transactions 2004-2019",
  inv_trans_0023: "Number of investor transactions 2000-2023",
  sm_inv: "Number of small investor transactions 2004-2019",
  med_inv: "Number of medium investor transactions 2004-2019",
  lrg_inv: "Number of large investor transactions 2004-2019",
  inst_inv: "Number of institutional investor transactions 2004-2019",
  li_inv: "Number of large and institutional investor transactions 2004-2019",
  llc_inv: "Number of LLC investor transactions 2000-2023",
  value_inv: "Number of value investor transactions 2000-2023",
  count_inv: "Number of count investor transactions 2004-2019",
  build_inv: "Number of building type investor transactions 2000-2023",
  cash_trans: "Number of cash buyer transactions 2000-2023",
  flip_count: "Number of flips 2002-2021",
  con_inv: "Single family: number of investor transactions 2004-2019",
  sf_inv: "Single family: number of investor transactions 2004-2019",
  r2f_inv: "Two family: number of investor transactions 2004-2019",
  r3f_inv: "Three family: number of investor transactions 2004-2019",
  inv_p: "Share total investor transactions 2004-2019",
  sm_inv_p: "Share small investor transactions 2004-2019",
  med_inv_p: "Share medium investor transactions 2004-2019",
  lrg_inv_p: "Share large investor transactions 2004-2019",
  inst_inv_p: "Share institutional investor transactions 2004-2019",
  li_inv_p: "Share large and institutional investor transactions 2004-2019",
  llc_inv_p: "Share LLC investor transactions 2000-2023",
  val_inv_p: "Share value investor transactions 2000-2023",
  c_inv_p: "Share count investor transactions 2004-2019",
  bld_inv_p: "Share building type investor transactions 2000-2023",
  cash_p: "Share cash buyer transactions 2000-2023",
  flip_p: "Share flip transactions 2002-2021",
  con_inv_p: "Condos: share investor transactions 2004-2019",
  sf_inv_p: "Single family: share investor transactions 2004-2019",
  r2f_inv_p: "Two family: share investor transactions 2004-2019",
  r3f_inv_p: "Three family: share investor transactions 2004-2019",
  aland: "Current land area (square meters)",
  awater: "Current water area (square meters)",
};

export const censusDisplayLabels = {
  title: "2020 Tract Boundaries (Census Tracts)",
  tbl_table: "CENSUS2020TRACTS_POLY",
  descriptn:
    "The 2020 cartographic boundary shapefiles are simplified representations of selected geographic areas from the U.S. Census Bureau's Master Address File test",
  objectid: "Internal feature number.",
  shape: "Feature geometry.",
  statefp: "Current state Federal Information Processing Series (FIPS) code",
  countyfp: "Current county Federal Information Processing Series (FIPS) code",
  tractce: "Current census tract code",
  geoid:
    "Census tract identifier; a concatenation of current state Federal Information Processing Series (FIPS) code",
  name: "Current census tract name",
  namelsad:
    "Current name and the translated legal/statistical area description for census tract",
  mtfcc: "MAF/TIGER Feature Class Code (MTFCC)",
  funcstat: "Current functional status",
  aland: "Current land area (square meters)",
  awater: "Current water area (square meters)",
  intptlat: "Current latitude of the internal point",
  intptlon: "Current longitude of the internal point",
};

export const displayables = [
  "trans_0023",
  "trans_0221",
  "trans_0419",
  "inv_trans_0419",
  "inv_trans_0023",
  "inv_p",
  "li_inv_p",
  "cash_p",
  "flip_p",
];

export const filterables = [
  "trans_0023",
  "trans_0221",
  "trans_0419",
  "inv_trans_0419",
  "inv_trans_0023",
  // "li_inv_p",
  // "sm_inv",
  // "med_inv",
  // "lrg_inv",
  // "inst_inv",
  // "llc_inv",
  // "value_inv",
  // "count_inv",
  // "build_inv",
  // "cash_trans",
  "inv_p",
  "sm_inv_p",
  "med_inv_p",
  "lrg_inv_p",
  "inst_inv_p",
  "llc_inv_p",
  "val_inv_p",
  "c_inv_p",
  "bld_inv_p",
  "cash_p",
  // "flip_count",
  "flip_p",
  // "tot_muni",
  // "muni_inv_p",
  "sf_inv",
  "sf_inv_p",
];

export const muniDisplayables = [
  "muni_trans_0023",
  "muni_trans_0221",
  "muni_trans_0419",
  "muni_llc_inv",
  "muni_inv_p",
  "muni_li_inv_p",
  "muni_cash_p",
  "muni_flip_p",
];

export const displayableSet = new Set(displayables);

export const mapboxBaseURL = "https://api.mapbox.com/";
export const mapboxAttribution = `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>`;

export const defaultMetric = "inv_p";
export const layerColors = {};
/*
export const layerColors = {
  tot_trans: ["#ccffed", "#3d9979", "#003321"],
  llc_inv: ["#93a585", "#494a69", "#291e33"],
  inv_p: ["#93a585", "#847fa2", "#000"],
  li_inv_p: ["#93a585", "#675c61", "#000"],
  cash_p: ["#e4f5e1", "#93a585", "#003321"],
  flip_p: ["#fcf8f8", "#713553", "#3c1111"],
};
*/

export const layerClassCounts = {
  llc_inv: 4,
  flip_p: 4,
};

export const layerContinuous = {
  // flip_p: false,
};

export const layerClasses = {
  trans_0023: {
    ct: [0, 1000, 1500, 2000, 5500],
    muni: [0, 1500, 5000, 10000, 175000]
  },
  trans_0221: {
    ct: [0, 1000, 1500, 2000, 5500],
    muni: [0, 1500, 5000, 10000, 175000]
  },
  trans_0419: {
    ct: [0, 1000, 1500, 2000, 5500],
    muni: [0, 1500, 5000, 10000, 175000]
  },
  inv_trans_0419: {
    ct: [0, 200, 300, 400, 1500],
    muni: [0, 250, 750, 1500, 44500]
  },
  inv_trans_0023: {
    ct: [0, 200, 300, 400, 1500],
    muni: [0, 250, 750, 1500, 44500]
  },
  inv_p: {
    ct: [0,0.15,0.20,0.30,1],
    muni: [0,0.1,0.2,0.3,1]
  },
  li_inv_p: {
    ct: [0, 0.15, 0.3, 0.6, 1],
    muni: [0, 0.15, 0.3, 0.45, 1]
  },
  cash_p: {
    ct: [0, 0.15, 0.3, 0.45, 1],
    muni: [0, 0.2, 0.3, 0.4, 1]
  },
  flip_p: {
    ct: [0, 0.05, 0.1, 0.2, 1],
    muni: [0, 0.05, 0.1, 0.15, 1]
  },
}
