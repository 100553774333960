import React from "react";
import {
  useSelections,
  useSelectionsDispatch,
} from "../context/SelectionsContext.js";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { Gear, X } from "react-bootstrap-icons";

import { displayLabels } from "../constants";

const LeavletControl = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  border-radius: 5px;
  background-color: white;
  background-clip: padding-box;
  box-shadow: none;
  max-width: ${(props) => (props.expanded ? "initial" : "fit-content")};
`;

const ControlsContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-height: 300px;
`;

const ControlsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
`;

const ControlsLogoTitle = styled.div`
  display: ${(props) => (props.expanded ? "flex" : "none")};
  flex: 1;
  gap: 1rem;
  align-items: stretch;
  justify-content: flex-start;
  svg {
    margin-top: 0.3rem;
    /* Need to correct for font height */
  }
`;

const ControlsTitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0px;
`;

const ControlsToggle = styled.button`
  flex: 1;
  width: 35px;
  max-width: 35px;
  height: 35px;
  max-height: 35px;

  /*background-color: ${(props) => props.theme.offwhite};*/
  pointer-events: auto;

  border: none;
  background-color: white;
  padding: 0px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 1rem;
  min-width: 300px;
`;

const Control = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const ControlHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
`;

const ControlLabel = styled(Form.Label)`
  margin-bottom: 0px;
  padding-top: 2.5px; /* needed to fix some issue with Calibre font vertical alignment */
`;

const ControlSwitch = styled(Form.Switch)`
  pointer-events: auto;
`;

const NoControls = styled.p``;

export const ControlPanel = ({
  controlsId,
  icon = <Gear size={20} />,
  title = "Controls",
  iterables = [],
  ControlComponent = <></>,
  options = { switchable: true },
  children,
}) => {
  //const theme = useTheme();
  const dispatch = useSelectionsDispatch();
  const { selectedControls } = useSelections();
  const expanded = selectedControls === controlsId;
  const onClose = (e) => {
    dispatch({
      type: "SET_SELECTED_CONTROLS",
      payload: null,
    });
  };

  const onOpen = (e) => {
    dispatch({
      type: "SET_SELECTED_CONTROLS",
      payload: controlsId,
    });
  };

  return (
    <LeavletControl
      expanded={expanded}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <ControlsContainer>
        <ControlsHeader>
          <ControlsLogoTitle expanded={expanded}>
            <ControlsTitle>{title}</ControlsTitle>
            {icon}
          </ControlsLogoTitle>
          <ControlsToggle onClick={expanded ? onClose : onOpen}>
            {expanded ? <X size={20} /> : icon}
          </ControlsToggle>
        </ControlsHeader>
        {expanded && children}
        {expanded && (
          <Controls>
            {iterables.length > 0 &&
              iterables.map(([i, iterable]) => (
                <Control key={i}>
                  <ControlHeader>
                    <ControlLabel>{`${displayLabels[i]}`}</ControlLabel>
                    {options.switchable && (
                      <ControlSwitch
                        checked={iterable.enabled}
                        onChange={(e) => {
                          // TODO: Set this up better so the controlpanel doesn't need to know about filters (instead, pass in function to call as props from parent component)
                          iterable.onEnableChange
                            ? iterable.onEnableChange(e)
                            : dispatch({
                                type: `SET_FILTER_ENABLED`,
                                payload: { name: i, enabled: e.target.checked },
                              });
                        }}
                      />
                    )}
                  </ControlHeader>
                  <ControlComponent
                    iterables={iterables}
                    i={i}
                    iterable={iterable}
                  />
                </Control>
              ))}
          </Controls>
        )}
        {expanded && (iterables == null || iterables.length === 0) && (
          <NoControls>No controls available</NoControls>
        )}
      </ControlsContainer>
    </LeavletControl>
  );
};

export default ControlPanel;
