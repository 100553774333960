import React from "react";
import {
  useSelections,
  useSelectionsDispatch,
} from "../context/SelectionsContext.js";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import {
  Funnel /*, CaretLeftFill, CaretRightFill*/,
} from "react-bootstrap-icons";
import ControlPanel from "./ControlPanel";
import { displayFormat } from "../util";

const ControlContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const RangeControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;

  input.form-range {
    flex: 7;
  }
`;

const RangeValue = styled(Form.Control)`
  width: calc(4rem + ${(props) => String(props.max).length}ch) !important;
  margin-bottom: 0.75rem;
`;

const RangeTerminus = styled.div`
  flex: 1;
  padding-top: 4px;
  text-align: center;
`;

const Range = styled(Form.Range)`
  pointer-events: auto;
`;

const NumericControl = styled(InputGroup)`
  width: initial;
  /*
  width: fit-content;
  min-width: fit-content;
  max-width: fit-content;
  */
`;

const InputDecoration = styled(InputGroup.Text)`
  height: 100%;
`;

const ResetButton = styled(Button)`
  flex: 1;
  max-width: fit-content;
  margin: 0.5rem;
`;

/*
const FilterMetadata = styled.div`
  flex: 1;
`;

const RangeStep = styled(Button)`
  :disabled {
    color: #dee2e6;
    border-color: #dee2e6;
  }
`;
*/

const ControlComponent = ({ iterables, i, iterable }) => {
  const dispatch = useSelectionsDispatch();
  const {
    map: { selectedLayer },
  } = useSelections();
  let min = 0;
  let max = selectedLayer === "Census Tracts" ? iterable.max : iterable.muniMax;

  // const [inputValue, setInputValue] = useState(Number(iterable.value));
  const inputValue = iterable.value;

  const percentage = i.endsWith("_p");
  if (percentage) {
    max = 1;
  }

  return (
    <ControlContainer>
      <RangeControl>
        <RangeTerminus>{displayFormat(i, min)}</RangeTerminus>
        {/*
        <RangeStep
          variant="outline-primary"
          size="sm"
          disabled={!iterable.enabled || iterable.value === min}
          onClick={(e) => {
            dispatch({
              type: `SET_FILTER_VALUE`,
              payload: { name: i, value: Number(iterable.value - 1) },
            });
          }}
        >
          <CaretLeftFill size={20} />
        </RangeStep>
        */}
        <Range
          min={min}
          max={max}
          value={iterable.value}
          disabled={!iterable.enabled}
          step={iterable.max <= 1 ? "0.01" : "1"}
          onChange={(e) => {
            dispatch({
              type: `SET_FILTER_VALUE`,
              payload: { name: i, value: Number(e.target.value) },
            });
          }}
        />
        {/*
        <RangeStep
          disabled={!iterable.enabled || iterable.value === max}
          variant="outline-primary"
          size="sm"
          onClick={(e) => {
            dispatch({
              type: `SET_FILTER_VALUE`,
              payload: { name: i, value: Number(iterable.value + 1) },
            });
          }}
        >
          <CaretRightFill size={20} />
        </RangeStep>
        */}
        <RangeTerminus>{displayFormat(i, max)}</RangeTerminus>
      </RangeControl>
      <NumericControl size="sm">
        <InputDecoration>At least</InputDecoration>
        <RangeValue
          type="number"
          value={
            percentage
              ? (Number(inputValue) * 100).toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : Number(inputValue).toString()
          }
          disabled={!iterable.enabled}
          max={max}
          min={min}
          onChange={(e) => {
            let newValue;
            if (percentage) {
              newValue = Number(e.target.value.replace(/%/g, "")) / 100;
            } else {
              newValue = Number(e.target.value);
            }
            if (newValue > max) {
              newValue = max;
            } else if (newValue < min) {
              newValue = min;
            }
            dispatch({
              type: `SET_FILTER_VALUE`,
              payload: { name: i, value: Number(newValue) },
            });
          }}
        />
        {percentage && <InputDecoration>%</InputDecoration>}
      </NumericControl>
    </ControlContainer>
  );
};

export const FiltersPanel = (props) => {
  const dispatch = useSelectionsDispatch();
  const { filters } = useSelections();
  /*
  const { filters, map, metric: selectedMetric } = useSelections();
  const filterStatuses = Object.values(filters).map((f) => f.enabled);
  const filterValues = Object.values(filters).map((f) => f.value);

  let metadataContent = useRef("");
  useEffect(() => {
    console.log("filters", filters);
    const noFiltersSelected = !Object.values(filters).some((f) => f.enabled);
    if (noFiltersSelected) {
      metadataContent.current = "No filters currently enabled";
    } else {
      console.log("map", map);
      const features = Object.values(
        map.selectedLayer === "Municipalities" ? map.muniData : map.ctData,
      );
      const filteredFeatures = features.filter((feature) => {
        for (let [metric, filter] of Object.entries(filters)) {
          console.log("metric", metric);
          console.log("feature", feature);
          console.log("filter", filter);
          console.log("feature[metric]", feature[metric]);

          if (filter.enabled && feature[metric] <= filter.value) {
            return false;
          }
        }
        return true;
      });
      console.log("filteredFeatures", filteredFeatures);
      console.log("filteredFeatures.length", filteredFeatures.length);
      const countMatchingFeatures = features.length - filteredFeatures.length;
      metadataContent.current = `${countMatchingFeatures} of ${features.length} ${map.selectedLayer} matching current filters`;
    }
  }, [
    filters,
    filterStatuses,
    filterValues,
    map.selectedLayer,
    map,
    selectedMetric,
    metadataContent,
  ]);
  */

  return (
    <ControlPanel
      title="Filters"
      icon={<Funnel size={20} />}
      iterables={Object.entries(filters)}
      ControlComponent={ControlComponent}
      {...props}
    >
      <ResetButton
        size="sm"
        onClick={(e) => {
          dispatch({
            type: "RESET_FILTERS",
          });
        }}
      >
        Reset all filters
      </ResetButton>
      {/*<FilterMetadata>
        <span>{metadataContent.current}</span>
      </FilterMetadata>
      */}
    </ControlPanel>
  );
};

export default FiltersPanel;
