import React from "react";
import chroma from "chroma-js";
import {
  useSelections,
  useSelectionsDispatch,
} from "../context/SelectionsContext.js";
import { displayables, displayLabels, layerClasses } from "../constants";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { BarChartLineFill } from "react-bootstrap-icons";
import ControlPanel from "./ControlPanel";

const ControlContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

const ControlSwitch = styled(Form.Switch)`
  pointer-events: auto;
`;

const ControlLabel = styled(Form.Label)`
  margin-bottom: 0px;
  padding-top: 2.5px; /* needed to fix some issue with Calibre font vertical alignment */
`;

const ControlComponent = ({ iterables, i, iterable }) => {
  return <></>;
};

export const MetricsPanel = (props) => {
  const dispatch = useSelectionsDispatch();
  const { map, metric, metricData } = useSelections();
  // Note: the label/title for this was changed to "Layers" based on feedback from the team
  // This (and related data) is referred to as "Metrics" everywhere else in the code (for now)
  return (
    <ControlPanel
      title="Layers"
      icon={<BarChartLineFill size={20} />}
      iterables={displayables.map((d) => [
        d,
        {
          enabled: d === metric,
          onEnableChange: (e) => {
            const geography = map.selectedLayer === "Municipalities"? "muni": "ct";
            const selectedMetric = map.selectedLayer === "Municipalities"? `muni_${d}`: d;
            let classes = layerClasses[metric][geography]
            dispatch({
              type: "SET_COLORS",
              payload: {
                metric: d,
                colors: {
                  classes,
                  domain: chroma.limits(metricData[selectedMetric].values, "l", 10),
                },
              },
            });
            dispatch({
              type: `SET_METRIC`,
              payload: d,
            });
          },
        },
      ])}
      ControlComponent={ControlComponent}
      {...props}
    />
  );
};

export default MetricsPanel;
