import React, { useCallback } from "react";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import Table from "react-bootstrap/Table";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { reportBody, reportToC } from "../report.js";

const Emphasis = styled.span`
  font-style: italic;
`;

const Summary = styled.summary`
  font-weight: bold;
`;

const Details = styled.details`
  margin-bottom: 1rem;
`;

export const Report = () => {
  const PartialTable = useCallback(
    (props) => <Table bordered hover {...props} />,
    [],
  );

  const Anchor = useCallback((props) => {
    return (
      <HashLink smooth to={`${props.href}`}>
        {props.children}
      </HashLink>
    );
  }, []);

  const PartialEm = useCallback((props) => <Emphasis {...props} />, []);

  const IdentifiedHeader = useCallback(
    (props) => (
      <h2 id={`${props.children.replace(/ /g, "-").toLowerCase()}`} {...props}>
        {props.children}
      </h2>
    ),
    [],
  );

  const IdentifiedHeaderThree = useCallback(
    (props) => (
      <h3 id={`${props.children.replace(/ /g, "-").toLowerCase()}`} {...props}>
        {props.children}
      </h3>
    ),
    [],
  );

  const TitleHeader = useCallback(
    (props) => (
      <>
        <h1 style={{marginBottom: "0rem"}}  {...props}>{props.children}</h1>
        <PartialEm>An Analysis of Investor Activity in the Greater Boston Residential Real Estate Market, 2000 - 2022</PartialEm>
        <Details style={{marginTop: "1rem", marginBottom: "1rem"}}>
          <Summary>Contents</Summary>
          <Markdown>{reportToC}</Markdown>
        </Details>
      </>
    ),
    [],
  );

  const SlideEmbed = useCallback(
    (props) => (
        <iframe style={{width: "100%", border: "2px solid rgba(0,0,0,0.6)", marginBottom: "1rem"}} src="https://slides.com/mapc/deck-266f4d/embed?byline=hidden&share=hidden" width="576" height="420" title="Homes for Profit: Tenant Experiences" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    ),
    [],
  );

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        table: PartialTable,
        em: PartialEm,
        a: Anchor,
        h1: TitleHeader,
        h2: IdentifiedHeader,
        h3: IdentifiedHeaderThree,
        hr: SlideEmbed,
      }}
      options={{ skipHtml: true }}
    >
      {reportBody}
    </Markdown>
  );
};

export default Report;
