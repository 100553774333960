export const theme = {
  offwhite: "#F2F5FF",
  // Blue MC2050 theme colors
  light: "#8ACCFF",
  mid: "#0063E6",
  dark: "#00256E",
  altLight: "#4DDCF2",
  altMid: "#0097C4",
  altDark: "#101436",
  money: "#85bb65",
  accentPrimary: "#85bb65", // Money altname
  mapMax: "#00256E",
  mapMid: "#0063E6",
  mapMin: "#8ACCFF",
};

export default theme;
