import React from "react";
// import logo from "../logo.svg";
// import { Footer } from "mapc-design-system";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { tint, shade } from "polished";
import { Outlet, useLocation } from "react-router-dom";
import { Houses, CurrencyDollar } from "react-bootstrap-icons";

import theme from "../theme";

import { DataSidebar } from "../components/DataSidebar";
import { SelectionsProvider } from "../context/SelectionsContext";

const GlobalStyle = createGlobalStyle`
          .leaflet-container a.leaflet-popup-close-button:hover {
  color: ${(props) => tint(0.33, props.theme.light)};
          }
          .leaflet-popup ul li:nth-child(even){
            background-color: ${(props) => shade(0.15, props.theme.offwhite)};
          }
        `;

const Page = styled.div`
  background-color: ${(props) => props.theme.offwhite};
  color: ${(props) => props.theme.dark};

  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
`;

const Header = styled.header`
  flex: 1;

  background-color: ${(props) => props.theme.mid};
  color: ${(props) => props.theme.offwhite};

  display: flex;
  padding: 1rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;
  max-height: 5rem;
`;

const LogoTitle = styled.a`
  flex: 1;
  display: flex;
  align-items: center;
`;

const HeaderText = styled.h1`
  flex: 1;
  margin-left: calc(0.5rem + 5px);
  margin-bottom: 0rem;
`;

const TabNav = styled.nav`
  flex: 1;
`;

const Tabs = styled.ul`
  display: flex;
  gap: 2.5rem;
  justify-content: flex-end;
  margin-right: 2.5rem;
  margin-bottom: 0rem;
  list-style: none;
`;

const Tab = styled.li`
  padding: 0.25rem 0.25rem 0rem 0.25rem;
  border-bottom: ${(props) =>
    props.selected ? `5px solid ${props.theme.offwhite}` : "none"};
`;

const TabLink = styled.a`
  color: ${(props) => (props.unavailable ? "#DEDEDE" : props.theme.offwhite)};
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
`;

const Content = styled.main`
  flex: 1 0 auto;

  display: flex;
  /* =80 to make room for footer */
  max-height: calc(100% - 80px);
  overflow-y: scroll;
`;

const InnerContent = styled.article`
  flex: 1 0 auto;

  display: flex;
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`;

const Money = styled(CurrencyDollar)`
  margin-left: -20px;
  margin-top: 4px;
`;

const clearCache = () => {
  localStorage.removeItem("appCache-ctPolygons");
  localStorage.removeItem("appCache-muniPolygons");
  localStorage.removeItem("appCache-updateTimestamp");
};

export const Root = ({ children }) => {
  const location = useLocation();
  return (
    <>
      <ThemeProvider theme={theme}>
        <SelectionsProvider>
          <Page>
            <Header>
              <LogoTitle onClick={clearCache} href="/">
                <LogoBox>
                  <Houses color={theme.offwhite} size={30} />
                  <Money color={theme.money} size={15} />
                </LogoBox>
                <HeaderText>Homes for Profit</HeaderText>
              </LogoTitle>
              <TabNav>
                <Tabs id="tabs">
                  <TabLink href={`/`}>
                    <Tab
                      selected={
                        location.pathname === "/" ||
                        location.pathname.startsWith("/map")
                      }
                    >
                      Map
                    </Tab>
                  </TabLink>
                  <TabLink href={`/executive-summary`}>
                    <Tab selected={location.pathname.startsWith("/executive-summary")}>
                      Executive Summary
                    </Tab>
                  </TabLink>
                  <TabLink href={`/report`}>
                    <Tab selected={location.pathname.startsWith("/report")}>
                      Report
                    </Tab>
                  </TabLink>
                  <TabLink href={`/about`}>
                    <Tab selected={location.pathname.startsWith("/about")}>
                      About
                    </Tab>
                  </TabLink>
                  {/*
                <TabLink unavailable={true} href={`/path3`}>
                  <Tab>Case Studies</Tab>
                </TabLink>
*/}
                </Tabs>
              </TabNav>
            </Header>
            <Content>
              <DataSidebar />
              <InnerContent>
                <Outlet />
              </InnerContent>
            </Content>
          </Page>
          <GlobalStyle />
        </SelectionsProvider>
      </ThemeProvider>
    </>
  );
};

export default Root;
