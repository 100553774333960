import {
  filterables,
  layerColors,
  layerClassCounts,
  layerContinuous,
} from "../constants";
import { theme } from "../theme";
import { createContext, useContext, useReducer } from "react";
import cloneDeep from "lodash-es/cloneDeep";

const SelectionsContext = createContext(null);

const SelectionsDispatchContext = createContext(null);

const defaultPercentageStyle = {
  scale: [theme.mapMin, theme.mapMid, theme.mapMax],
  classCount: 4,
  continuous: false,
  domain: [0, 1],
  mode: "lch",
  correctLightness: true,
  colorAssignmentMode: "l",
};

const defaultAbsoluteStyle = {
  scale: [theme.mapMin, theme.mapMid, theme.mapMax],
  classCount: 4,
  continuous: false,
  domain: [0, 10000],
  mode: "lch",
  correctLightness: true,
  colorAssignmentMode: "q",
};

const modalPreference = localStorage.getItem("appCache-hideModal");
let hideModal = false;
if (modalPreference != null && modalPreference === "true") {
  hideModal = true;
}

const initialSelections = {
  muni: null,
  tract: null,
  state: null,
  latLong: null,
  selectedControls: null,
  metric: "inv_p",
  metricData: {},
  map: {
    selectedLayer: "Census Tracts",
  },
  colors: {
    byMetric: {},
    mapMax: theme.mapMax,
    mapMid: theme.mapMid,
    mapMin: theme.mapMin,
    scale: [theme.mapMin, theme.mapMid, theme.mapMax],
    classes: 5,
    classCount: 4,
    continuous: false,
    domain: [0, 1],
    mode: "lch",
    correctLightness: true,
    colorAssignmentMode: "l",
  },
  filters: {...Object.fromEntries(
    filterables.map((f) => [
      f,
      {
        value: 0,
        min: 0,
        max: 99999,
        muniMin: 0,
        muniMax: 9999,
        enabled: false,
      },
    ]),
  ),
    li_inv_p: {
        value: 0,
        min: 0,
        max: 99999,
        muniMin: 0,
        muniMax: 9999,
        enabled: false,
      },
  },
  hideModal
};

/*
const initializer = (initialValue) => {
  const storedValue = localStorage.getItem("appCache");
  return storedValue && storedValue !== "undefined"
    ? JSON.parse(storedValue)
    : initialValue;
};
*/

export const SelectionsProvider = ({ children }) => {
  const [selections, dispatch] = useReducer(
    selectionsReducer,
    initialSelections,
    //     initializer,
  );

  /*
  // FIXME: Can't use localstorage because it's too small to store this data =[
  useEffect(() => {
    localStorage.setItem("appCache", JSON.stringify(selections));
  }, [selections]);
  */

  return (
    <SelectionsContext.Provider value={selections}>
      <SelectionsDispatchContext.Provider value={dispatch}>
        {children}
      </SelectionsDispatchContext.Provider>
    </SelectionsContext.Provider>
  );
};

export const useSelections = () => {
  return useContext(SelectionsContext);
};

export const useSelectionsDispatch = () => {
  return useContext(SelectionsDispatchContext);
};

const selectionsReducer = (selections, action) => {
  switch (action.type) {
    case "SET_HIDE_MODAL": {
      const { payload: hideModal } = action;
      return {
        ...selections,
        hideModal,
      };
    }
    case "SET_STATE": {
      const { payload: state = {} } = action;
      return {
        ...selections,
        state,
      };
    }
    case "SET_MUNI": {
      const { payload: muni = {} } = action;
      return {
        ...selections,
        muni,
      };
    }
    case "SET_TRACT": {
      const { payload: tract = {} } = action;
      return {
        ...selections,
        tract,
      };
    }
    case "SET_LAT_LONG": {
      const { payload: latLong = {} } = action;
      return {
        ...selections,
        latLong,
      };
    }
    case "SET_FILTERS": {
      const { payload: filters = {} } = action;
      return {
        ...selections,
        filters,
      };
    }
    case "SET_FILTER_LIMITS": {
      const { payload: filterLimits = {} } = action;
      const newFilters = {};
      Object.keys(selections.filters).forEach((f) => {
        const filter = selections.filters[f];
        filter.min = filterLimits[f].min;
        filter.max = filterLimits[f].max;
        if (filter.value == null || filter.value < filter.min) {
          filter.value = filter.min;
        } else if (filter.value > filter.max) {
          filter.value = filter.max;
        }
        newFilters[f] = filter;
      });
      return {
        ...selections,
        filters: {
          ...selections.filters,
          ...newFilters,
        },
      };
    }
    case "SET_MUNI_FILTER_LIMITS": {
      const { payload: filterLimits = {} } = action;
      const newFilters = {};
      Object.keys(selections.filters).forEach((f) => {
        const muniKey = `muni_${f}`;
        const filter = selections.filters[f];
        filter.muniMin = filterLimits[muniKey].min;
        filter.muniMax = filterLimits[muniKey].max;
        newFilters[f] = filter;
      });
      return {
        ...selections,
        filters: {
          ...selections.filters,
          ...newFilters,
        },
      };
    }
    case "SET_FILTER_VALUE": {
      const { payload: filter = {} } = action;
      return {
        ...selections,
        filters: {
          ...selections.filters,
          [filter.name]: {
            ...selections.filters[filter.name],
            value: filter.value,
          },
        },
      };
    }
    case "RESET_FILTERS": {
      const allFilters = selections.filters;
      for (let filter of Object.values(allFilters)) {
        filter.enabled = false;
        filter.value = filter.min;
      }
      return {
        ...selections,
        filters: allFilters,
      };
    }
    case "SET_FILTER_ENABLED": {
      const { payload: filter = {} } = action;
      return {
        ...selections,
        filters: {
          ...selections.filters,
          [filter.name]: {
            ...selections.filters[filter.name],
            enabled: filter.enabled,
          },
        },
      };
    }
    case "SET_METRIC": {
      const { payload: metric } = action;
      return {
        ...selections,
        metric,
      };
    }
    case "SET_METRIC_DATA": {
      const { payload: metricData = {} } = action;
      return {
        ...selections,
        metricData: {
          ...selections.metricData,
          ...metricData,
        },
      };
    }
    case "SET_MUNI_METRIC_DATA": {
      const { payload: metricData = {} } = action;
      return {
        ...selections,
        metricData: {
          ...selections.metricData,
          ...metricData,
        },
      };
    }
    case "SET_MAX_COLOR": {
      const { payload: mapMax } = action;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          mapMax,
        },
      };
    }
    case "SET_MID_COLOR": {
      const { payload: mapMid } = action;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          mapMid,
        },
      };
    }
    case "SET_MIN_COLOR": {
      const { payload: mapMin } = action;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          mapMin,
        },
      };
    }
    case "SET_INDEX_COLOR": {
      const {
        payload: { metric, i, color },
      } = action;
      const newScale = cloneDeep(selections.colors.byMetric[metric].scale);
      newScale[i] = color;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          byMetric: {
            ...selections.colors.byMetric,
            [metric]: {
              ...selections.colors.byMetric[metric],
              scale: newScale,
            },
          },
        },
      };
    }
    case "SET_CONTINUOUS_COLORS": {
      const {
        payload: { metric, continuous },
      } = action;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          byMetric: {
            ...selections.colors.byMetric,
            [metric]: {
              ...selections.colors.byMetric[metric],
              continuous,
            },
          },
        },
      };
    }
    case "SET_COLOR_MODE": {
      const {
        payload: { metric, mode },
      } = action;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          byMetric: {
            ...selections.colors.byMetric,
            [metric]: {
              ...selections.colors.byMetric[metric],
              mode,
            },
          },
        },
      };
    }
    case "SET_COLOR_CLASS_COUNT": {
      const {
        payload: { metric, classCount },
      } = action;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          byMetric: {
            ...selections.colors.byMetric,
            [metric]: {
              ...selections.colors.byMetric[metric],
              classCount,
            },
          },
        },
      };
    }
    case "SET_COLOR_ASSIGNMENT_MODE": {
      const {
        payload: { metric, colorAssignmentMode },
      } = action;
      return {
        ...selections,
        colors: {
          ...selections.colors,
          byMetric: {
            ...selections.colors.byMetric,
            [metric]: {
              ...selections.colors.byMetric[metric],
              colorAssignmentMode,
            },
          },
        },
      };
    }
    case "SET_COLORS": {
      const {
        payload: { metric, colors },
      } = action;
      let currentColors = selections.colors.byMetric[metric];
      if (currentColors == null) {
        // Switch defaults based on whether metric represents a percentage
        currentColors = metric.endsWith("_p")
          ? { ...defaultPercentageStyle }
          : { ...defaultAbsoluteStyle };
      }
      if (layerColors[metric]) {
        currentColors.scale = layerColors[metric];
      }
      if (layerClassCounts[metric]) {
        currentColors.classCount = layerClassCounts[metric];
      }
      if (layerContinuous[metric]) {
        currentColors.continuous = layerContinuous[metric];
      }
      return {
        ...selections,
        colors: {
          ...selections.colors,
          byMetric: {
            ...selections.colors.byMetric,
            [metric]: {
              ...colors,
              ...currentColors,
            },
          },
        },
      };
    }
    case "SET_CT_POLYGONS": {
      const { payload: ctPolygons } = action;
      return {
        ...selections,
        map: {
          ...selections.map,
          ctPolygons,
        },
      };
    }
    case "SET_CT_DATA": {
      const { payload: ctData } = action;
      return {
        ...selections,
        map: {
          ...selections.map,
          ctData: Object.fromEntries(
            ctData.map((f) => [f.properties.geoid, f.properties]),
          ),
        },
      };
    }
    case "SET_MUNI_POLYGONS": {
      const { payload: muniPolygons } = action;
      return {
        ...selections,
        map: {
          ...selections.map,
          muniPolygons,
        },
      };
    }
    case "SET_MUNI_DATA": {
      const { payload: muniData } = action;
      return {
        ...selections,
        map: {
          ...selections.map,
          muniData: Object.fromEntries(
            muniData.map((f) => [f.properties.muni_id, f.properties]),
          ),
        },
      };
    }
    case "SET_STATE_DATA": {
      const { payload: stateData } = action;
      return {
        ...selections,
        map: {
          ...selections.map,
          stateData: { MA: stateData[0].properties },
        },
      };
    }
    case "SET_SELECTED_CONTROLS": {
      const { payload: selectedControls } = action;
      return {
        ...selections,
        selectedControls,
      };
    }
    case "SET_SELECTED_LAYER": {
      const { payload: selectedLayer } = action;
      return {
        ...selections,
        map: {
          ...selections.map,
          selectedLayer,
        },
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};
