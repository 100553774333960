export const hackyHeaders = (about) => {
  let regexMatch;

  const headerFinder = /<u>(.*)<\/u>\n/g;
  while ((regexMatch = headerFinder.exec(about)) !== null) {
    about = about.replace(regexMatch[0], `## ${regexMatch[1]}\n`);
  }
  return about;
};

export const aboutMarkdown = hackyHeaders(`Our research defines four different types of investors: Count investors, Value investors, LLC investors, and Building investors. The investor types are not mutually exclusive, and investors often fall into more than one category. We also distinguish investors by size: small, medium, large, and institutional. Investors that are tagged as multiple investor types (e.g. Count investor and LLC investor) are given a final size designation of the largest investor size category they qualify as.

<u>Count investors:</u>

A ‘count’ investor is any buyer who purchased 3 or more residential properties within any five-year window across the study period.

Investors under this definition are further categorized by size based on the number of purchases within any 5-year window, as defined below.

-   Small investor = 3 purchases

-   Medium investor = 4-5 purchases

-   Large investor = 6-9 purchases

-   Institutional investor = 10 or more purchases

<u>Value investors:</u>

A ‘value’ investor is defined based on the average annual value of their purchases over the timespan of the data (23 years). To be considered an investor under this definition a buyer must have an annual average purchase value of at least \$150,000 in 2022 USD.\* For the study period from 2000 to 2022, this totals \$3.45 million. Investors under this definition are further categorized by size based on their average annual spending, as defined below.

-   Small investor = \$150,000 - \$224,999 (\$3.45 to \$5.17 million total from 2000 through 2022)

-   Medium investor = \$225,000 - \$449,999 (\$5.18 to \$10.34 million total from 2000 through 2022)

-   Large investor = \$450,000 - \$2,299,999 (\$10.35 to \$52.89 million total from 2000 through 2022)

-   Institutional investor = \$2,300,000 or more (\$52.90 million or more total from 2000 through 2022)

<u>LLC/LLP investors:</u>

Any purchase made by an LLC or LLP is considered an investor purchase in our analysis.

LLCs and LLPs that don’t otherwise meet the criteria above for the Medium, Large, and Institutional size categories are defined as Small Investors; Small LLC Investors may have fewer than three purchases over a five-year period and total purchase values less than an average of \$150,000 annually.

<u>Building investors:</u>

Under this definition, any buyer who purchased at least one apartment building, mixed-use residential building, or a building classified as ‘Other Residential’ is considered an investor.

Building Investors that don’t otherwise meet the criteria above for the Medium, Large, and Institutional size categories are defined as Small Investors; Small Building Investors may have fewer than three purchases over a five-year period and total purchase values less than an average of \$150,000 annually.

<u>Flips:</u>

We define a flip as a transaction in which a property is sold within two years of its most recent purchase date, with some notable exceptions. We have made four exclusions from this definition: 1) sales by banks, government sponsored enterprises (Fannie Mae/Freddie Mac); or local, state, or federal government agencies (e.g. HUD); 2) foreclosures; 3) transactions in which a property is resold by a lender, servicer, or mortgage insurance company that was acquired as a result of a distressed sale through a foreclosure (i.e. the previous transaction for a property was a foreclosure); and 4) same day sales.

\*Note: We have excluded from our investor definition buyers who otherwise meet the definition of a Value Investor but have 1) only purchased single family homes and 2) currently owns only one of those single-family properties. This decision was made to acknowledge the high home prices in many areas of the MAPC region and avoid counting individuals who own one multi-million-dollar home at a time.
`);

export default aboutMarkdown;
