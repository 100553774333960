export const hackyFigures = (report) => {
  let regexMatch;

  let position = 0;

  const figureFinder = /\n\*Figure (\d+)\. (.*)\*\n/g;
  while ((regexMatch = figureFinder.exec(report.slice(position))) !== null) {
    const figureTemplate = `\n![${regexMatch[2]}](${process.env.PUBLIC_URL}/images/figure_${regexMatch[1]}.png)\n_Figure ${regexMatch[1]}. ${regexMatch[2]}_\n`;
    report = report.replace(regexMatch[0], figureTemplate);
    position = regexMatch[0].index + figureTemplate.length;
  }

  /*
  const figureXFinder = /\n\*Figure X\. (.*)\*\n/g;
  regexMatch = figureXFinder.exec(report);
  const figureXTemplate = `\n![${regexMatch[1]}](${process.env.PUBLIC_URL}/images/figure_x.png)\n_Figure X. ${regexMatch[1]}_\n`;
  report = report.replace(regexMatch[0], figureXTemplate);
  */

  return report;
};

export const stripSpans = (report) => {
  const doc = new DOMParser().parseFromString(report, "text/html");
  return doc.body.textContent || "";
};

export const getReportToC = (report) => {
  const tocLines = [];
  for (let line of report.split("\n")) {
    if (line.trim().startsWith("- ")) {
      tocLines.push(line);
    } else {
      break;
    }
  }
  return tocLines.join("\n");
};

export const getReportBody = (report) => {
  return report.replace(/.+?\n(?=# )/s, "");
};

export const reportMarkdown = hackyFigures(
  stripSpans(`-   [Executive Summary](#executive-summary)
-   [Introduction](#introduction)
-   [Data and Definitions ](#data-and-definitions)
-   [The Presence of Housing Investors in Greater Boston](#the-presence-of-housing-investors-in-greater-boston)
-   [The Investor Advantage](#the-investor-advantage)
-   [Investment is Not Distributed Equally](#investment-is-not-distributed-equally)
-   [Flips](#flips)
-   [The Impact of Investment on the Greater Boston Housing Market](#the-impact-of-investment-on-the-greater-boston-housing-market)
-   [Policy Recommendations ](#policy-recommendations)
    -   [Discouraging Speculation](#discouraging-speculation)
    -   [Generating Resources](#generating-resources)
    -   [Providing Assistance](#providing-assistance)

# Homes for Profit: Speculation and Investment in Greater Boston

## Executive Summary

Buying a home has long been a critical opportunity for building wealth in America, yet over the past several decades that opportunity has moved farther out of reach for most households. In today’s Boston Metro housing market, an annual income of nearly \$200,000 is needed to afford a median-priced home.[^1] Renters struggle, too, to afford a place to stay, with rent increases outpacing income growth: a record 51% of Greater Boston renters spend more than 30%—and more than a quarter spend more than half—of their income on housing costs.[^2]

Decades of inadequate housing production across Greater Boston, coupled with strong economic growth attracting workers to the region, and resulting shortages in housing stock have led us into the housing crisis that we face today. Amidst this crisis, another phenomenon has emerged that has only made the situation worse: investors betting their funds on housing. Sometimes called corporate investors, institutional investors, or speculators, these are entities purchasing residential property with profit, not shelter, as a primary goal. This report examines the scale and scope of housing investment as a practice in Greater Boston.  

The impact of this practice cannot be overstated. Not only do investors take properties off the market that could otherwise be sold to families intending to live in a home, but renters often suffer from investors buying their buildings and hiking up their rents, if not evicting them outright without cause. Through several case studies of buildings recently sold to investors in the Greater Boston area, we see new investor-owners, often those with large real estate portfolios hidden behind numerous LLCs, ask long-term tenants to pay rent increases of up to 70% or leave the building they have long called home. 

Through an analysis of residential real estate transactions, our research finds that 21% of residential properties sold in Greater Boston in the period from 2004 through 2018 were purchased by an investor. We define four types of residential property investors: 1) count investors, who purchased more than three residential properties within any five year window in the study period; 2) LLC investors, who purchased any residential property through an LLC, 3) building investors, who purchased any residential building with four or more units, and 4) value investors, who spent at least \$3.45 million on residential properties over the 23-year period, or an average of at least \$150,000 annually throughout the period. These definitions are not mutually exclusive, and many investors are classified as multiple investor types. We categorize these investors into four investor size groups, with small, medium, large, and institutional investors defined respectively by their total annual spending and the scale and pace of their acquisitions during the study period. We also investigate the practice of flipping properties, defined as any property sold within two years of its most recent purchase date, with notable exceptions for foreclosures and same-day sales. We examine the frequency of flipping and the profit margin for flipping properties by investors and non-investors. We explore the spatial patterns of residential investment and flipping, and the underlying racial and socioeconomic dynamics those patterns reveal.  

This research exposes clear and worrisome trends in speculative residential real estate investment in Greater Boston. In addition to finding that one in five residential transactions from 2004 through 2018 were conducted by investors, our analysis also finds that the rate of residential investor activity has grown over time. While investor activity represented 16% of sales in 2004, that number rose to 23% in 2018, with significantly higher investment rates in two-family (over 30% in 2018) and three-family (nearly 50% in 2018) acquisitions. Our analysis also finds that investors come to the table with a clear advantage: cash. Cash offers are more appealing to sellers than traditional mortgages, so much so that cash offers are often accepted even if they are not the highest bid, allowing buyers to purchase properties at a discount. More than half of investors who purchased condominiums during the study period did so in cash, with similarly high proportions for single-family (43%), two-family (45%), and three-family (39%) purchases.  

Speculative residential investment is far from evenly distributed in Greater Boston. Using MAPC’s [<u>Housing Submarkets</u>](https://housing-submarkets.mapc.org/) to analyze investor activity in Greater Boston’s varied housing markets, our research finds that investor activity is most likely to occur in the region’s high-density urban submarket with relatively low housing prices (Submarket 2), which is home to the highest share of renters, Black, Indigenous, and People of Color (BIPOC), and immigrant populations of the region’s seven submarkets. Investors made nearly one-third of all purchases over the study period in Submarket 2. Households in this submarket already face the highest cost burdens, with a quarter of households spending more than half their income on rent. Likewise, investor activity was high in the densest and most expensive submarket, Submarket 1, as well as in the moderate-density moderately priced Submarket 3, which has a mix of high- and low-cost housing and racial and immigrant populations comparable to the regional average. While we see less investor activity in suburban markets with high prices, they too have experienced an increase in investor activity since the 2008 recession. 

We find that 9% of residential buildings bought in Greater Boston between 2002 and 2022 were “flipped” within the next two years, with the highest flip rates among apartment buildings (12%) and three-family homes (11%). Large and institutional investors were the most likely to flip the homes they purchased, with nearly a quarter of single-family homes and a fifth of two-family homes purchased by large or institutional investors being flipped, compared to rates of just 8 and 9% respectively for non-investor buyers. While flips occurred at similar rates across submarkets, we found flipping to be most common in Greater Boston’s highest value submarket, Submarket 5. Comprised of low-density, high value suburban areas, flips in this submarket contribute to pushing these already “exclusive” neighborhoods farther out of reach for the average Massachusetts resident. Finally, we find that investors resell their flipped properties for significantly more than the original purchase price, compared to non-investors. Since 2010, investors who flipped their single-family properties have seen re-sale prices a median of 55 to 85% higher than they originally paid for the properties; by comparison, non-investors who flip properties have seen re-sale prices at a median of only 12 to 25% higher in the same period. 

In response to these trends, we recommend a set of policy solutions to discourage speculation, generate revenue for affordable housing from the speculation that does occur, and provide housing assistance and greater stability to Massachusetts residents. Policies such as establishing a tenants’ right of first refusal and regulating rents, as well as state and local foreclosure protections and acquisition programs, first time homebuyer assistance, and municipal property tax reform could help improve housing affordability in Massachusetts, especially by retaining the affordability of some of the housing that already exists. Where implemented, these policies reduce incentives for speculation by expanding market access for affordable housing developers, limiting the potential profits from speculation, and better enabling low- and moderate-income homeowners to stay in their homes. We further identify local option transfer fees and statewide deeds excise increases as potential mechanisms to capture and reinvest revenue from investment activity. Through their basis in a percentage of a property’s value, these mechanisms allow both state and local government to ensure that revenue generated for affordable housing can keep pace with rapid escalations in housing costs. Transfer fees have the added benefit of potentially deterring speculative investors with large portfolios, and disincentivizing short-term flipping. These mechanisms ensure that a measure of the value, which investors extract from communities is reinvested in those communities to mitigate housing instability, and along the way help to reduce housing instability brought on by speculators buying up residential real estate without community benefit in mind. 

## Introduction

Speculation in the housing market is an emerging issue in communities across the country. Home sale prices in many regions continue to rise, even throughout the COVID-19 pandemic, and while rents in many cities dipped early on in the pandemic, most are now back to or above pre-pandemic peaks. Potential buyers seeking a home to live in often find themselves in bidding wars, sometimes against bidders offering cash, that result in sales tens of thousands of dollars above the asking price. Residents, tenant organizations, and nonprofit affordable housing developers have experienced pressure on the rental market from speculators buying residential properties as investment opportunities and subsequently raising rents sharply, evicting lower-income tenants in favor of higher paying tenants, converting the units to condominiums, flipping the units for a large profit, or leaving them to sit empty, thereby taking precious units off the market.

Housing is necessary for survival, and homeownership is one of the cornerstones of building wealth in America. Homebuyers often expect the return on a home investment to be higher than inflation, a savings account, or even the stock market. This investment is aided by significant tax benefits that occur both during the time that one lives in their home and when they sell it. However, the path for a family to purchase a single-family home, condo, or triple-decker to live in and rent out is becoming farther out of reach because of the unprecedented pressure on the housing market. The Metropolitan Area Planning Council’s (MAPC) research presented in this report shows that highly capitalized investors and cash buyers are taking over a significant share of the market and pushing out more traditional and non-profit buyers.

Our research also shows that lower-income neighborhoods of color are those most likely to experience housing speculation. These neighborhoods and communities have experienced the effects of systemic racism for generations, from redlining and segregation to subprime loan predation and mortgage discrimination. Today, many of these neighborhoods are home to some of the last remaining unsubsidized affordable homeownership opportunities in the region. We find that, rather than going to residents who identify as Black, Indigenous, and People of Color (BIPOC) or first-time homeowners, many of these homes are going to speculators. Households of color in Greater Boston are significantly less likely to own their homes than White households. In 2021, two-thirds of White-headed households owned their homes, whereas one-third of Black, half of Asian, and only 29 percent of Latinx-headed households in the region owned their homes.[^1] Black and Latino mortgage applicants, [even those with high incomes](https://mapc.gitbook.io/metrocommon-x-2050-indicators/high-income-mortgage-denial-rate-gaps-by-race-and-ethnicity), are much more likely to be [denied a loan](https://www.wbur.org/news/2022/03/30/home-loans-mortgages-boston-denials) than White applicants. State and federal programs focusing on low-income or first-generation homebuyers may seek to reduce the racial wealth gap, but these programs are often not equipped to function in a fast-moving housing market and are rarely sufficient to compete with cash buyers. 

While many struggle to enter Greater Boston’s prohibitively expensive homeownership market, others are cashing in on it. All home purchases are an investment, but the focus of this research is to identify and examine the extreme cases where profit is the primary aim. A family or individual buying a house to live in may know that they are making an investment, for themselves and their future generations, but they are also buying a home for the stability and autonomy that come with it. Corporate and large-scale buyers typically purchase properties as short-to-long-term investments. These buyers are often called corporate investors, institutional investors, or speculators; for simplicity throughout this report, we will refer to these buyers as investors. Some investors flip their properties for a quick profit; others maintain them as short-term, long-term, or vacation rentals; and others simply leave them sitting empty. <span class="mark">Research</span> shows that the rapid purchase and turnover of homes can squeeze tight housing markets by reducing available housing stock and increasing prices, making it difficult for residents entering the market to purchase a permanent home and potentially contributing to residential displacement pressures. There is [evidence](https://www.homesforallmass.org/covid-evictions-report/hfamass-covid-evictions-report-short-03-22-screen-hr.pdf), too, that corporate landlords are more likely to file evictions than those who live in the same building as their tenants.

One alternative to the extractive and often negative impact of speculative housing investment on communities is housing investment by community-based organizations, such as community development corporations (CDCs) and community land trusts (CLTs). There are many such organizations throughout Massachusetts, and they come to property ownership with a mission to support the communities they serve and provide stable and affordable housing options for residents. Yet, they must compete—as nonprofit and cooperative organizations with limited cash on hand—with highly resourced investors to buy residential properties.[^2]

This report provides a comprehensive analysis of where speculative housing investments have occurred in Greater Boston, what kinds of housing stock they are taking off the market, and just how much profit investors are making. We find that 21% of transactions in Greater Boston in the years spanning 2004 through 2018 were by an investor. Our research also shows investor purchasers are more likely to buy properties with cash, purchase homes at a discount, and flip properties for profit. This research helps us understand who is investing in Greater Boston’s housing market and the impact that investment is having on the market and on individual communities in the region.

## Data and Definitions

To conduct this analysis, MAPC relied on real estate transaction data purchased from The Warren Group, which includes all residential property transactions in the MAPC region from 2000 through 2022. The data include the address of each property, the name of the buyer—whether it is an individual, company, trust, or bank—the property type, and the previous sale date and price of the unit, along with many other details. With this information, we are able to provide evidence about investment in Greater Boston’s housing market that, to our knowledge, does not otherwise exist.

The Warren Group dataset includes just over one million real estate transactions in the MAPC region. As shown in Table 1, the vast majority of transactions (87%) are single family home and condominium purchases.

| Residential Type            | \# Transactions | % of Transactions |
|-----------------------------|-----------------|-------------------|
| Residential 1 Family        | 543,858         | 53.9%             |
| Residential 2 Family        | 78,636          | 7.8%              |
| Residential 3 Family        | 31,317          | 3.1%              |
| Condominiums                | 332,744         | 33.0%             |
| Residential Apartments      | 11,029          | 1.1%              |
| Other Residential Buildings | 11,360          | 1.1%              |

Table 1. Warren Group Real Estate Transactions by Residential Type, MAPC Region

<img src="media/image1.png" style="width:6.47679in;height:3.19792in" alt="A graph of a graph showing different colored bars Description automatically generated with medium confidence" />

*Figure 1. Total Number of Real Estate Transactions by Residential Building Type and Year, MAPC Region*

Our analysis focuses on separating ‘investor’ purchases from ‘non-investor’ purchases. We have developed four criteria to determine if a buyer is an ‘investor,’ defined below. Due to the five-year period needed to determine investor status under the *number of transactions* definition, we only define investors through the year 2018.

> ***Count Investors:*** Based on number of transactions; buyers who purchased at least three residential properties within a five-year period.
> 
> ***Value Investors:*** Based on value of transactions; a buyer whose total purchase amount totaled \$3.45 million (an average of \$150,000 annually throughout the period) or more across the entire 23-year period.
> 
> ***LLC Investors:** Corporate* transactions; any LLC, regardless of total number of transactions or total dollars spent by that LLC.
> 
> ***Building Investors:*** Based on size of building; a buyer of any building with four or more units, regardless of total number of transactions or total dollars spent by that buyer.

We have further broken these investors down into categories of different sizes.

> ***Institutional Investors:*** Buyers whose purchase amount totaled \$52.90 million (an average annual purchase value of at least \$2.3 million) or more over the 23-year period; buyers who have purchased 10 or more properties in any five-year period.
> 
> ***Large Investors:*** Buyers whose purchase amount totaled between \$10.35 to \$52.89 million (an average annual purchase value of at least \$450,000 and less than \$2.3 million) over the 23-year period; buyers who have purchased six to nine properties in any five-year period.
> 
> ***Medium Investors:*** Buyers whose purchase amount totaled between \$5.18 to \$10.34 million (an average annual purchase value of at least \$225,000 and less than \$450,000) over the 23-year period; buyers who have purchased four to five properties in any five-year period.
> 
> ***Small Investors:*** Buyers whose purchase amount totaled between \$3.45 to \$5.17 million (an average annual purchase value of at least \$150,000 and less than \$225,000) over the 23-year period; buyers who have purchased only three properties in any five-year period; all LLCs that are not otherwise classified as ‘medium,’ ‘large,’ or ‘institutional;’ buyers who have purchased an apartment building with four or more units and are not otherwise classified as ‘medium,’ ‘large,’ or ‘institutional.’

| Investor Size | \# Transactions | % of Investor Transactions |
|---------------|-----------------|----------------------------|
| Small         | 60,659          | 44%                        |
| Medium        | 27,161          | 20%                        |
| Large         | 15,687          | 11%                        |
| Institutional | 34,159          | 25%                        |

Table 2. Warren Group Real Estate Investor Transactions by Investor Size, MAPC Region

Our analysis also investigates residential property flipping, which we have defined as any property that has been sold within two years of its most recent purchase date, with four exceptions: 1) sales by banks, government sponsored enterprises (Fannie Mae/Freddie Mac); or local, state, or federal government agencies (e.g., the U.S. Department of Housing and Urban Development (HUD)); 2) cases where a home was foreclosed within two years of being purchased; 3) transactions in which a property is resold by a lender, servicer, or mortgage insurance company in which the property was acquired as a result of a distressed sale through a foreclosure (i.e. the previous transaction for a property was a foreclosure); and 4) same day sales. While these four transaction types are interesting actions in the housing market, they do not tell a story of ‘flips’ as we typically think of them in terms of investments.

We have further categorized flips as ‘buy-side flips’ and ‘sell-side’ flips. Buy-side flips are the purchase of a property that *will eventually be flipped*. A sell-side flip is the transaction in which the property is resold. By our definition, the maximum length of time between a buy-side flip and a sell-side flip is two years.

For more information about our methods, see the technical documentation in the About section of the Homes for Profit website. For detailed code used to analyze our data, see our [GitHub repository](https://github.com/MAPC/homes-for-profit).

## The Presence of Housing Investors in Greater Boston

Overall, our data finds that **21%** **of transactions in Greater Boston 2004 - 2018 were by an investor purchaser.** This is a staggering statistic, especially when we think about how crowded the Greater Boston market has become. Investor activity has also increased over the study period, from 16% in 2004 to 23% in 2018 (see Figure 4).

The charts below tell a story of the changing presence of investor purchases in single family, condominium, and two- to three-family buildings. We find that investment purchases of two- and three-family buildings started ramping up in the years leading up to the Great Recession, peaked in 2008, and remained high in the decade following. Single family and condominium sales did not see the same investor activity leading up to 2008, but investment in these types of properties did climb slightly from 2006 to 2008 and remain steady through 2018.

<img src="media/image2.png" style="width:6.49089in;height:3.24544in" />

*Figure 2. Investor Purchases by Residential Building Type and Year MAPC Region*

Because of the high rate of foreclosures during and following the Great Recession, we wanted to better understand how much of the investment activity was driven by investors purchasing foreclosed properties. Removing foreclosed properties from our analysis revealed a slightly different story. The increase in investment during the Great Recession is still there for two- and three-families, but it is less pronounced. Following the Great Recession, we see a steady increase in investment activity across all residential types that continues through the rest of the study period. These trends together suggest that not only were investors collecting foreclosed properties through the recession period, but that those with money also saw the recession and years of recovery following it as an opportunity to get into the Greater Boston housing market at a time with relatively lower prices and potential for significant portfolio and profit growth.

<img src="media/image3.png" style="width:6.07422in;height:3.54329in" />

*Figure 3. Investor Purchases by Residential Building Type and Year, MAPC Region, Excluding Foreclosures*

Investor types have shown relatively consistent patterns of property investment over the years, with Count Investors at the top with the highest share of transactions, followed by Value Investors, and then for much of the study period, Building Investors then LLC Investors. While the investor transaction shares have changed over the years, investor types have stayed mostly similar relative to each other, except for LLC investors. As shown in the figure below, LLC investors had the smallest share of investor transactions in the early years of our study period and remained lower than other types of investors through the Great Recession. However, in the years following the recession, starting in 2013, LLC investors maintained a consistent rate of investment, while other types of investors slowed somewhat. In 2016 LLC Investors surpassed the transaction share of Building Investors, and in 2022 they had the same transaction share as Value Investors, following a sharp increase in transaction share during the COVID-19 years. In 2000, LLC Investors had a transaction share of one percent; by 2008 it had risen sharply to 10 percent; and in 2022 it remained high at nine percent.

*Figure 4. Investor Purchases by Investor Type as a Share of All Transactions. Note: Count investors are not shown for the full period because they are identified by summarizing data within a rolling five-year window. Investors may be multiple types of investors, such as Count Investors and Value Investors, so the percentages shown in this chart will exceed the total share of investor transactions in a given year. Additionally, this chart was adjusted in April of 2024 to fix an error in the identification of LLC transactions where non-LLC purchases were incorrectly double-categorized as LLCs. See our technical documentation for more details on this change.*

Looking at investor purchases by investor size reveals a clear picture of the investment dynamics during and after the 2008 recession. During the recession period, the share of purchases by small investors dipped slightly from the start of the study period, and the share of purchases by institutional investors increased by as much as 10 percentage points. Those who were able to invest in property during the Great Recession did so with many transactions, altering the balance of ownership in the Greater Boston market that likely still impacts it today. Institutional investor purchases dropped back down by 2013, though they remain two to three percentage points higher in recent years compared to before the recession. Small investor transactions dipped slightly during the recession, resumed after it, and have steadily become a greater share of transactions, ending the study period with a three percentage point higher share compared to 2004.

<img src="media/image4.png" style="width:6.5in;height:3.48958in" />

*Figure 5. Investor Purchases by Investor Size as a Share of All Transactions, MAPC Region*

## Greater Boston Tenant Stories

---

## The Investor Advantage

While we can speculate that the commodification of housing and presence of investors in the market is likely to increase competition and prices for other buyers, it’s important to examine the advantage investors have over non-investor buyers in the market. We find that investors are much more likely than non-investors to purchase in cash. Investors are more than twice as likely than non-investors to purchase in cash when buying condominiums and three-family buildings, and more than three times as likely when buying single-family and two-family homes.

<img src="media/image5.png" style="width:6.48551in;height:3.24173in" />

*Figure 6. Percent of Properties Purchased with Cash by Investor Status and Real Estate Type, MAPC Region*

Cash sale purchases have grown from less than 15% of all sales in the years between 2000 and 2006 to more than 20% of sales in the years since 2012, with spikes up to more than 30% in the years following the 2008 recession.

<img src="media/image6.png" style="width:6.40278in;height:3.84167in" alt="A graph showing a line of sales Description automatically generated with medium confidence" />

*Figure 7. Percent of Real Estate Transactions Purchased with Cash by Year, MAPC Region*

Cash buying puts investors at an advantage over non-investors because cash sales are typically more attractive to sellers, who then do not have to deal with financing paperwork and can therefore close on their sale faster; and as our analysis shows, cash sales often allow investors to purchase property at a discounted price. The median sale price for cash-purchased properties, including condos, 1-, 2-, and 3-family homes, is consistently lower than that of properties of the same sizes purchased with a mortgage.

<img src="media/image7.png" style="width:6.83857in;height:3.17708in" />

*Figure 8. Annual Median Sales Price, Condominiums, 1, 2, and 3 Family Properties, MAPC Region, Cash vs Non-Cash Sales*

The chart above shows the difference in annual median sales price between cash and non-cash sales for the length of our study. The sharp differential between cash and non-cash sales appears to start right after the Great Recession and continues through to today. In 2020 the difference between the median price for cash and noncash sales was \$100,000. The price differential is notable for all residential property types but is most significant for single-family and three-family homes. This price differential is another way that investors are cutting non-investors out of the market even further. The chart below shows the difference in annual median sale price between cash and non-cash sales for condos only. As expected, the median sale price is lower with the removal of 1, 2, and 3 family properties, but the overall trend for condos alone is distinct as well. From 2000 to 2007 and again in 2021 and 2022, the median sales price for a condo purchased with cash was higher than the non-cash median. Even when the cash sale median is lower than the non-cash median, the difference between the two is smaller than what is observed in the chart above.

<img src="media/image8.png" style="width:6.73973in;height:3.075in" />

*Figure 9. Annual Median Sales Price, Condominiums, MAPC Region, Cash vs Non-Cash Sales*

While investors may be able to purchase properties at a discount, collectively their dollars add up. In 2018, the total dollar amount spent by investors on residential property in Greater Boston was nearly \$10 billion, up \$2 billion from \$8 billion in 2004 after adjusting for inflation.

<img src="media/image9.png" style="width:6.01454in;height:3.32053in" alt="A graph showing a line Description automatically generated" />

*Figure 10. Total Value of Purchases made by Investors by Year, in Millions, MAPC Region*

## Investment is Not Distributed Equally

While the entire Greater Boston region is plagued by high housing prices and housing scarcity, the activity of investors varies across the region. MAPC’s [housing submarkets](https://housing-submarkets.mapc.org/) are a useful tool to characterize differences across our region. A housing submarket is a collection of neighborhoods—some next to each other, some not—with similar housing stock and housing market characteristics. MAPC’s Housing Submarkets analysis identified seven housing typologies in the MAPC region. The submarkets are ordered from Submarket 1 to Submarket 7 based on decreasing housing unit density, with Submarket 1 the densest urban areas of the region, and Submarket 7 the least dense suburban areas. The submarkets are also categorized according to housing costs, renter and owner shares, changes in costs, and more. The neighborhoods in each submarket share common needs and challenges, regardless of their geographic location. To learn more about MAPC's housing submarkets and the people living in them, see MAPC’s [Housing Submarkets](https://housing-submarkets.mapc.org/) website.

<img src="media/image10.png" style="width:6.5in;height:6.5in" alt="A picture containing map, text Description automatically generated" />

*Figure 11. MAPC’s Housing Submarkets*

| MAPC’s Housing Submarkets |                                                     |                                                                                                                              |                                                                                                                                                                                                                    |
|------------|---------------------|---------------------|--------------------|
| Title                     | Description                                         | Housing Characteristics                                                                                                      | Characteristics of People and Households Living in the Submarket                                                                                                                                                   |
| Submarket 1               | Highest density, high housing costs                 | Premium-prices, increasing home prices and rents, declining numbers of renters                                               | Generally similar to the overall region: 34% BIPOC and 25% immigrant populations; 41% of households and 47% of renters cost-burdened                                                                               |
| Submarket 2               | High density, lower housing costs                   | Highest renter share, high rates of foreclosure and cash sales, highest rate of business buyers, rapid sale price escalation | Largest share of BIPOC (65%) and immigrant (35%) populations; highest rates of cost burden at 52% for all households and 56% for renters.                                                                          |
| Submarket 3               | Moderate-Density Urban, Moderate Housing Costs      | Oldest housing stock, mix of single-family and small multifamily, increasing home prices and rents                           | BIPOC (46%) and immigrant (30%) population shares higher than the overall region; housing cost burden similar to the overall region for all households (40%) and renters (46%).                                    |
| Submarket 4               | Low-Density Urban-Suburban Mix, Lower Housing Costs | Older housing stock in suburbs and regional urban centers, minimal change in rents, slowest population growth                | BIPOC (22%) and immigrant (16%) population shares lower than the overall region; housing cost burden for all households (34%) slightly lower than, and for renter households (49%) the same as the overall region. |
| Submarket 5               | Low-Density Suburban, Highest Housing Costs         | Large, older housing stock in very expensive single-family home neighborhoods, low and declining renter share                | BIPOC (21%) and immigrant (17%) population shares lower than the overall region; lowest rates of housing cost burden for all households (28%) and for renter households (45%).                                     |
| Submarket 6               | Low-Density Suburban, Mixed Housing Costs           | Suburban edges with the newest housing stock and lowest home prices, rapid increases in density, rents, and renters          | BIPOC (29%) and immigrant (23%) population shares slightly lower than the overall region; housing cost burden for all households (37%), and for renter households (49%) the same as the overall region.            |
| Submarket 7               | Low-Density Suburban, Moderate Housing Costs        | Moderate-cost single-family suburbs, newer housing stock, few renters                                                        | Smallest share of BIPOC (14%) and immigrant (11%) populations; lower rates of cost burden compared to the region at 28% for all households and 46% for renters.                                                    |

Table 3. Description of the residential density and housing characteristics of MAPC’s seven housing submarkets and the people who live in them.

<img src="media/image11.png" style="width:5.37217in;height:3.45833in" />

*Figure 12. Share of Transactions that are Investor Purchases by MAPC Submarket, 2004-2018*

Submarket 2, a high-density urban submarket with relatively low housing prices, high renter shares, and the highest share of BIPOC and immigrant populations of the seven submarkets, is the submarket with the highest rate of investor purchases. This submarket is also characterized by high rates of foreclosures, cash sales, and rapid price escalation. Nearly two-thirds of the population of Submarket 2 are Black, Latine, Asian, Indigenous, or multiracial, and 35% are immigrants. Households in Submarket 2 have the highest rate of severe cost burden among the submarkets, with 25% of households paying more than half of their income on housing costs; and high-income mortgage denial rates are highest in Submarket 2.

Submarkets 1 and 3 also have a higher-than-average share of investor purchases. Together, these tend to be characterized as dense neighborhoods, with some of the most low-cost and high-cost housing, and BIPOC and immigrant population shares that are equal to or greater than the regional average. Submarket 1 includes areas such as Downtown Boston and is characterized as a premium-priced, high-density submarket with increasing home prices and rents. Submarket 3 includes areas with moderate density and older housing stock, such as areas of Dorchester, Somerville, and Lynn.

Investment activity in the lower-priced submarkets 2 and 3 can increase competition and prices for what was once naturally occurring, moderately priced or affordable housing. The resulting rapid increase in values, and possibly rents, is a major driver of displacement.

Submarkets 5, 6, and 7 are the region’s mostly suburban submarkets, characterized by low-density suburban housing and lower BIPOC and immigrant population shares than the regional average. Generally, rates of investment in these three submarkets are lower. However, as seen below in Figure 13, the rate of investment in Submarket 5—the region’s highest-cost submarket, including areas of Lincoln, Weston, and Wellesley—has been steadily increasing since the 2008 recession, making these ‘exclusive’ communities even less attainable for the majority of the population.

<img src="media/image12.png" style="width:6.17958in;height:3.65625in" />

*Figure 13. Share of Transactions that are Investor Purchases by MAPC Submarket by Year*

Submarkets 2 and 3, both concentrated mostly in the region’s core, saw large increases in investment activity during the Great Recession that has remained high in the years since. On the other hand, investment activity in Submarkets 1 and 5, the region’s highest cost submarkets, appears to have built steadily in the years since the recession, trending more closely with non-foreclosure investor activity. Submarket 7, the lowest density and a lower-cost suburban market, has consistently had the lowest rate of investor activity since 2009.

## Flips

Another type of investment activity that is significantly altering the market for non-investor buyers is purchases made to ‘flip’ properties. We define a flip as a transaction in which a property is sold within two years of its most recent purchase date. For a more detailed definition of our definition of flips, see the Data and Definitions section above. Overall, 8.6% of residential buildings bought between 2002-2020 were then flipped within the next two years. We find that apartment buildings and three-family buildings are most likely to be flipped in the MAPC region, at 12% and 11%, respectively.

Buy-Side Flip Purchases in the MAPC Region, 2002-2020

| Residential Type       | Number of Flips | % of Transactions That are Flips |
|------------------------|------------------------|------------------------|
| Residential 1 Family   | 39,170          | 8.8%                             |
| Residential 2 Family   | 6,332           | 9.8%                             |
| Residential 3 Family   | 2,906           | 11.4%                            |
| Condominiums           | 21,065          | 7.6%                             |
| Residential Apartments | 1,063           | 12.0%                            |
| Mixed Use Residential  | 285             | 7.8%                             |
| Total                  | 70,821          | 8.6%                             |

Table 4. Buy-Side Flip Purchases in the MAPC Region by Residential Type, 2002-2020

While our analysis shows that investor purchase activity jumped up during the Great Recession, we find that there was a lull in flipping activity in the years leading up to and immediately after the recession. Flip rates before the recession were high, reaching 12% of all purchases in 2003, and declined to a low of six percent in 2007 through 2009. Rates increased to 10% by 2014 and stayed relatively steady at 9% or 10% through 2019. In 2020, the flip rate dipped down to 8%—possibly impacted by the COVID-19 pandemic.

<img src="media/image13.png" style="width:6.41536in;height:2.98047in" />

*Figure 14. Percent of Purchases That Became Flipped Properties by Year, MAPC Region, Excludes Foreclosures*

<img src="media/image14.png" style="width:6.49088in;height:3.24544in" />

*Figure 15. Percent of Purchases That Became Flipped Properties by Real Estate Type and Year, MAPC Region, Excludes Foreclosures*

After the recession, we saw increases in flip-rates for nearly every real estate type. We also found, contrary to common perception, condominium properties tend to have lower flip rates than other property types. Flip rates for condominiums were consistently three or more percentage points lower than single family homes between 2010 and 2019.

Institutional and Large investors are most likely to flip the homes they purchase. From 2004 to 2018, 20% of single-family and 17% of two-family buildings purchased by institutional investors were flipped, as were 21% of single-family and 19% of two-family buildings purchased by large investors. In comparison, only 7% of single-family and 8% of two-family buildings purchased by non-investors were flipped over the same period.

<img src="media/image15.png" style="width:6.5in;height:3.14583in" />

*Figure 16. Percent of Purchases That Became Flipped Properties by Investor Size and Real Estate Type, Excludes Foreclosures*

Home flipping occurs throughout the region but is most prevalent in Greater Boston’s highest value submarket, Submarket 5. Housing in Submarket 5, the region’s most exclusive suburbs, is already expensive and difficult to purchase. While outside of the scope of this research to investigate, housing experts often note the occurrence of small, naturally more affordable properties—or multiple properties—in these communities being purchased, made bigger, and re-sold at high values, a phenomenon sometimes referred to as “mansionization.”[^3]

<img src="media/image16.png" style="width:6.25276in;height:3.54323in" />

*Figure 17. Share of Purchases that Become Flipped Properties, By MAPC Submarket, 2002-2020, Excludes Foreclosures*

Flips have two important impacts on the region’s housing market. First, they take lower-priced houses off the market for potential owner-occupant buyers; the median purchase price for a home or building that will ultimately be re-sold within two years was \$160,000 less in 2020 than it was for those not sold within two years. That price differential has been steadily climbing since 2014. The chart below shows the difference in median sales price between properties that are eventually flipped and those that are not. We see the large gap in median prices start to form after the Great Recession and peak in 2020. Second, homes that are flipped are re-sold at a higher price, significantly higher when an investor is doing the flipping (as shown in Figure 19). These dynamics combined—purchasing at a discount and flipping at a profit—make it hard, if not impossible, for an average family to purchase in the market.

<img src="media/image17.png" style="width:7.23958in;height:3.22765in" />

*Figure 18. Annual Median Sales Price by Year, Flips vs Non-Flips, Excludes Foreclosures*

The median percent difference in sale price of homes flipped by investors (median percent change from “buy side” purchase price to “sell-side” flip price) has doubled or more since before the recession. Institutional investors have seen a 70 to 80 percentage point increase in their profits and non-institutional investors saw an increase of 20 to 50 percentage points. While investors are seeing large differences in the sales price of flipped single family homes, most years non-investors are experiencing differences below 20%.

<img src="media/image18.png" style="width:6.70573in;height:2.9617in" />

*Figure 19. Median Percent Difference in Sales Price of Flipped Single Family Homes, by Investor Type*

## The Impact of Investment on the Greater Boston Housing Market

The data we have analyzed from the past two decades fills in an important story about the impact of investment activity on our housing market. The housing market is full of competition, made even more crowded and out of reach by the presence of investors. Investor purchases account for 21% of all transactions in the MAPC region over the study period and are most prevalent in the two- and three-family home market, reaching 30% and 50%, respectively, in 2018. Investors are more likely to pay for properties using cash, often allowing them to purchase properties at lower prices than non-cash buyers. Although investment happens across the region, lower-cost urban neighborhoods of color experience the highest rate of investment activity, adding to displacement pressures and restricting homeownership opportunities. Institutional and large investors are buying up large swaths of properties at a discount and flipping them at a premium, further adding to the rapid increases in prices around the region.

Speculators are able to take off the market the little remaining naturally occurring affordable housing in the Greater Boston region. This activity by investors can exist because market systems and government policies have enabled housing to become a lucrative commodity rather than a necessity for survival and a human right. As they exist now, these systems and policies, and the investment activities that they allow, make the state’s goals of expanding homeownership opportunities and reducing racial wealth disparities an uphill battle that will be hard to win.

One of the reasons investors can profit in this market to the degree that they do is the lack of supply and relatively inelastic demand for housing in the Greater Boston region. An increase in supply of housing is profoundly needed in the region, yet increased supply alone will not solve the housing crisis on its own. It is nearly impossible for the average household to compete with investors who are able to pay cash for properties, resell, and use their profits to buy more housing. In addition, investors are likely to purchase properties from the existing housing stock instead of more expensive new construction. This further leads to the existing, more naturally affordable housing stock moving out of reach for the typical buyer.

In addition, financial resources that communities invest in their neighborhoods often generate an increase in property values, beyond the investment of individual property owners. The increase in property value that a homeowner sees because of a new mural down the street or a cleaned-up community garden has very little or nothing to do with the individual property owner. How different would the community look if that increase in property value were invested back into the neighborhood in the form of more attainable, affordable housing opportunities or more stable rental options?

Our current housing system does not work well for many low- to moderate-income renters and prospective homebuyers, while allowing a few investors to make immense profits. It is critical that we start examining the impact that the commodification of housing will have, not only on our ability to house people today, but also for future generations. This practice of housing speculation is unsustainable as a whole and will make it more difficult to achieve the region’s goals, as outlined in [MetroCommon 2050](https://metrocommon.mapc.org/), Greater Boston’s land use and policy plan, of safe and stable shelter for all in communities throughout the region.

## Policy Recommendations

State and local governments should take actions to reduce speculative real estate investment and to mitigate its impacts on tenants and local housing markets. Policies that reduce speculation can result in increased housing stability for Massachusetts tenants, and expand access to homeownership for individuals and families, particularly first-time homebuyers. Changes to taxes and financing can also rein in speculation and help balance local housing markets. In areas where speculation is occurring at higher rates, policies that generate revenue from speculative transactions and expand funding for affordable housing production and other community investments can help mitigate and reduce the amount of residential displacement that may occur at the neighborhood and community scale. The following policy opportunities, many of which are also recommended in the [Homes for Everyone Action Area of MetroCommon 2050](https://metrocommon.mapc.org/action-areas/housing), should be further explored to address the challenges of speculation in Greater Boston’s housing market.

### Discouraging Speculation

The policy recommendations outlined below aim to reduce speculation by limiting the market incentives which drive it.

#### Local Option Right of First Refusal

Speculative investors across Greater Boston often buy naturally occurring affordable properties only to raise rents to market rate and few mechanisms exist to support municipalities and their affordable housing partners in efforts to keep these homes affordable. Creating a local option to allow cities and towns to establish a tenant’s right of first refusal allows tenants in a building the right to either a) match a third-party offer when their homes are being sold, or b) designate that right to a non-profit, local housing authority, or other affordable housing developer. This provides a critical tool both to preserve naturally occurring affordable housing (NOAH) locally, and to prevent displacement when residential properties rapidly change hands, often leading to dramatic rental increases which price residents out of their homes. Providing educational materials and resources for tenants to understand their rights and opportunities under such laws is critical to the policy’s success as is diligently enforcing the policy. A number of municipalities and regions across the country have implemented some version of a tenants right of first refusal, including San Francisco, CA; Price George’s County, MD; Portland, OR; and Washington D.C. Establishing such a local option would allow municipalities with high levels of housing insecurity to take critical steps to mitigate displacement locally, while also changing the market dynamics that have led to the speculation’s prevalence.

##### Bills active in the 2023-2024 Legislative Session:

-   [H.1350](https://malegislature.gov/Bills/193/HD3645)/[S.880,](https://malegislature.gov/Bills/193/SD2238) *An Act to guarantee a tenants right of first refusal*, filed by Representatives Livingstone and Consalvo, and Senator Jehlen

#### Local Option Rent Stabilization

Rent stabilization policies are important in strong housing markets such as Greater Boston where speculative investors, without restrictions on how much they can increase rent, have countless opportunities to increase their profits through dramatic rent increases at the expense of tenants. Cities across the country have enacted rent regulations tailored to the local market and housing stock conditions. Rent stabilization policies can be crafted to help preserve rent affordability for tenants while also protecting a landlord’s investment to ensure buildings can be properly maintained and improved over time. However the policy is crafted, research shows that in order for rent regulations to improve tenant stability, these policies must remain in place between changes of ownership, otherwise the point of sale creates an opportunity to dramatically raise rents. Massachusetts prohibited rent regulation in 1994, but given the extreme rental price escalations and dramatic increases in renter cost-burden across income levels in the decades since, there is a renewed interest in allowing cities and towns to consider rent stabilization policies. Rent stabilization policies are allowed in several states, including California, Maine, Maryland, Minnesota, New York, New Jersey, Oregon, and Washington, and nearly 200 cities nationwide have enacted such policies to provide greater rental stability and help curb displacement.

##### Bills active in the 2023-2024 Legislative Session:

-   [<u>H.2103</u>](https://malegislature.gov/Bills/193/H2103)/<u>[S.1299](https://malegislature.gov/Bills/193/S1299),</u> *An Act enabling cities and towns to stabilize rents and protect tenants,* filed by Senators Jehlen and Gomez, Representatives Dave Rogers and Montaño

-   [<u>H.1304</u>](https://malegislature.gov/Bills/193/H1304)/[<u>S.872</u>](https://malegislature.gov/Bills/193/S872), *An Act enabling local options for tenant protections,* filed by Representative Connolly and Senator Eldridge

-   Boston Home Rule Petition: [H.3744](https://malegislature.gov/Bills/193/H3744), *A Petition for a special law authorizing the city of Boston to implement rent stabilization and tenant eviction protections,* filed by Representative Montaño

#### Limited Liability Company Beneficiary Transparency

While Limited Liability Companies (LLCs) have long played a role in the acquisition, development, and management of residential real estate, increases in the proportion of investor purchases over the past two decades, and increased rates of LLC investment, mean that LLCs are now buyers of an outsized proportion of real estate transactions in Greater Boston. The lack of transparency into LLC ownership and the end destination of LLC profits make it difficult to determine which individuals or entities have an interest in an LLC, shielding investors from proper review of conflicts of interest involving LLC’s real estate transactions, obscuring tax liability, and making the landscape of speculative investment hard to analyze. Providing greater transparency into the individuals or entities which have a beneficial interest in an LLC would both improve state government’s ability to pursue legal action in cases of conflicts of interest and better shield against tax evasion. Such transparency would also give tenants, researchers, and housing advocates more visibility into property ownership, and thus into who is shaping their communities. Requiring transparency can also reduce tax-related advantages that speculative investors have over individuals looking to purchase real estate.

##### Bills active in the 2023-2024 Legislative Session:

-   [H.3566](https://malegislature.gov/Bills/193/H3566), *An Act to ensure LLC transparency,* filed by Representative Uyterhoeven

### Generating Resources

The policy recommendations outlined below aim to generate resources that can be used to offset the impact of speculation and provide community benefits where speculation occurs.

#### Local Option Transfer Fees

State legislation that enables municipalities to collect fees on the transfer of certain real estate holdings to fund affordable housing would provide critically needed new revenue for local efforts to expand the Commonwealth’s stock of affordable housing. Transfer fees also help to ensure that revenue for affordable housing keeps pace with rising development costs. Because transfer fees are collected as a percentage of all transactions, as housing costs rise, the available revenue available for affordable housing also continues to rise Transfer fees may help to dissuade speculation by tightening profit margins for large and institutional investors and may reduce flipping because they increase the cost of reselling properties over a short period of time. Legislation should allow municipalities to determine if fees should be borne by buyers or sellers to accurately account for local market dynamics. Similar fees on the transfer of property are levied in Connecticut, Florida, Illinois, Michigan, New Hampshire, New York, Pennsylvania, Vermont, Washington, D.C. and a number of other jurisdictions across the United States.

##### Bills active in the 2023-2024 Legislative Session:

-   [H.4138](http://malegislature.gov/bills/193/h4138), *The Affordable Homes Act,* filed by Governor Healey

-   [<u>H.2747</u>](https://malegislature.gov/Bills/193/H2747)/<u>[S.1771](https://malegislature.gov/Bills/193/S1771),</u> *An act granting a local option for a real estate transfer fee to fund affordable housing,* filed by Representative Connolly and Senator Comerford

-   Home rule petitions filed by: Amherst, Arlington, Boston, Brookline, Cambridge, Concord, Nantucket, Provincetown, Somerville, Truro, Wellfleet and all six municipalities of Martha’s Vineyard (Aquinnah, Chilmark, Edgartown, Oak Bluffs, Vineyard Haven, and West Tisbury, filing jointly)

#### Statewide Deeds Excise Tax Increase

Massachusetts’ deeds excise tax is well below most neighboring states at just \$4.56 per \$1,000 of taxable property value. Doubling this rate to \$9.12 could generate an estimated \$300 million in additional annual revenue, which could be invested in affordable housing and anti-displacement measures, along with climate resiliency and building decarbonization measures, particularly in affordable housing. Statewide increases in point-of-sale fees on real estate transactions, like Massachusetts’ deeds excise tax, can generate additional revenue for affordable housing and help to combat displacement. Like locally levied transfer fees, deeds excise taxes ensure that revenue generated keeps pace with costs because they are based on property values.

##### Bills active in the 2023-2024 Legislative Session:

-   [<u>H.2894</u>](https://malegislature.gov/bills/193/h2894)/<u>[S.1799](https://malegislature.gov/bills/193/s1799),</u> *An Act* *providing* *for climate change adaptation infrastructure and affordable housing investments in the Commonwealth,* filed by Senator Eldridge and Representative Montaño

### Providing Assistance

The policy recommendations outlined below would provide direct assistance to residents to help them find and maintain stable housing in the short term.

#### Residential Assistance for Families in Transition (RAFT)

The RAFT program provides emergency rental and utility assistance to residents who are at risk of losing their home, or who have lost their home and are seeking to relocate. The program has grown significantly in recent years to account for need, expanding from \$20 million in FY20 to \$190 million in FY24. The RAFT program’s success speaks to the need to both increase funding for the program and codify it. As increased demand for the program remains steady, robust annual investments will be critical to preventing displacement through the current volatile housing market. Additionally, codifying the program, implementing improved data and outcome tracking, and eliminating barriers to entry in the application process will be critical to ensuring it can continue to meet demand generated by ongoing housing instability.

##### Bills active in the 2023-2024 Legislative Session:

-   [H.1312](http://malegislature.gov/bills/193/h1312%22%20HYPERLINK%20%22http://malegislature.gov/bills/193/H.1312)/S.856, *An Act providing upstream homelessness prevention assistance to families, youth, and adults*, filed by Representative Decker and Senator Crighton

#### Massachusetts Rental Voucher Program (MRVP)

MRVP provides long-term rental subsidies to low-income families to help them find and maintain stable housing. Like other housing assistance programs, MRVP has seen both increased demand and increased funding in recent years, with \$85 million allocated in FY17 and \$179 million allocated in FY24. As with these other programs, robust annual investment is critical to prevent displacement and ensure that low-income families have access to stable housing they can afford. Likewise, codification of the program is necessary to improve data collection and analysis, better target who receives these subsidies, and provide greater flexibility for households who do receive subsidies.

##### Bills active in the 2023-2024 Legislative Session:

-   [H.1351](http://malegislature.gov/bills/193/h1351)/S.888, *An Act codifying the Massachusetts Rental Voucher Program*, filed by Representative Madaro and Senator Lovely

#### First Time Homebuyer Assistance Programs

In competitive housing markets like Greater Boston, first-time homebuyers struggle to enter the market for many reasons, but speculative investors further complicate the homebuying process because of their quicker access to capital and ability to make cash offers. State and local governments should consider how homeownership and downpayment assistance programs can be nimbler to help low- and moderate-income buyers and first-generation homebuyers be better positioned to compete with cash-rich investors.

##### Bills active in the 2023-2024 Legislative Session:

-   [H.4138](https://malegislature.gov/Bills/193/H4138), *The Affordable Homes Act,* filed by Governor Healey

#### Foreclosure Protections

Increasing homeowner stability can result in fewer homes being available for sale to speculative investors. Establishing a statewide foreclosure prevention program which requires pre-foreclosure mediation to explore alternative remedies for homeowners at risk of foreclosure would both help to prevent displacement of owners and tenants and help low-income homeowners and homeowners of color to maintain their ability to build wealth through homeownership.

##### Bills active in the 2023-2024 Legislative Session:

-   [<u>H.942</u>](https://malegislature.gov/Bills/193/H942)/[<u>S.653</u>](https://malegislature.gov/Bills/193/S653), *An Act establishing a Massachusetts foreclosure prevention program,* filed by Senator Gomez, and Representatives Barber and Capano

#### Foreclosed property sale to affordable housing operators

State and local governments should work with partners in the financial industry to find ways to incentivize or prioritize sales of foreclosed properties to non-profits, community land trusts, affordable housing trusts, and other affordable housing operators. This can be an especially useful tactic for preserving naturally occurring affordable housing (NOAH).

#### Property Tax Rates

When homes are not owner-occupied and serve as rental or vacation homes, cities and towns could amend their tax codes to assess these properties at a higher tax rate than owner-occupied homes. This could disincentivize residential investments and provide relief for existing homeowners.

 

[^1]: Data Source: American Community Survey, 5-Year Estimates, 2017-2021.

[^2]: Due to the nature of our analysis, CDCs and CLTs are included as investors in this research. Separating them from the rest of the investors, while not impossible, was not possible within the constraints of this project. Doing so should be considered for future research. Despite the fact that we have not separated out CDC and CLT purchases, we are confident that they represent a small fraction of the investor purchases represented herein.

[^3]: [https://www.bostonmagazine.com/property/2018/10/11/teardown-wars-boston-suburbs/  
    ](https://www.bostonmagazine.com/property/2018/10/11/teardown-wars-boston-suburbs/)<https://newton.wickedlocal.com/news/20160629/newtons-big-teardown-as-demolitions-increase-some-areas-impacted-more-than-others>
`),
);

export const reportToC = getReportToC(reportMarkdown);
export const reportBody = getReportBody(reportMarkdown);

export default reportMarkdown;
