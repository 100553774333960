import React from "react";
import { useSelectionsDispatch } from "../context/SelectionsContext.js";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { InfoCircle, X } from "react-bootstrap-icons";

const LeavletControl = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  border-radius: 5px;
  background-color: white;
  background-clip: padding-box;
  box-shadow: none;
  max-width: ${(props) => (props.expanded ? "initial" : "fit-content")};
`;

const ControlsContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-height: 300px;
`;

const ControlsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
`;

const ControlsLogoTitle = styled.div`
  display: ${(props) => (props.expanded ? "flex" : "none")};
  flex: 1;
  gap: 1rem;
  align-items: stretch;
  justify-content: flex-start;
  svg {
    margin-top: 0.3rem;
    /* Need to correct for font height */
  }
  margin-right: 2rem;
`;

const ControlsTitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0px;
`;

const ControlsToggle = styled.button`
  flex: 1;
  width: 35px;
  max-width: 35px;
  height: 35px;
  max-height: 35px;

  /*background-color: ${(props) => props.theme.offwhite};*/
  pointer-events: auto;

  border: none;
  background-color: white;
  padding: 0px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 1rem;
`;

const Control = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const ControlHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
`;

const ControlLabel = styled(Form.Label)`
  margin-bottom: 0px;
  padding-top: 2.5px; /* needed to fix some issue with Calibre font vertical alignment */
  font-weight: bold;
`;

export const InfoModalButton = ({
  icon = <InfoCircle size={20} />,
  title = "Information",
  controlsId,
  selectedControls,
}) => {
  const dispatch = useSelectionsDispatch();
  const expanded = false;
  const onClose = (e) => {
    dispatch({
      type: "SET_SELECTED_CONTROLS",
      payload: null,
    });
  };

  const onOpen = (e) => {
    dispatch({
      type: "SET_SELECTED_CONTROLS",
      payload: controlsId,
    });
    dispatch({
      type: "SET_HIDE_MODAL",
      payload: false,
    });
  };

  return (
    <LeavletControl
      expanded={expanded}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <ControlsContainer>
        <ControlsHeader>
          <ControlsLogoTitle expanded={expanded}>
            <ControlsTitle>{title}</ControlsTitle>
            {icon}
          </ControlsLogoTitle>
          <ControlsToggle onClick={expanded ? onClose : onOpen}>
            {expanded ? <X size={20} /> : icon}
          </ControlsToggle>
        </ControlsHeader>
        {expanded && (
          <Controls>
            <Control>
              <ControlHeader>
                <ControlLabel>Displayed Geographies</ControlLabel>
              </ControlHeader>
            </Control>
          </Controls>
        )}
      </ControlsContainer>
    </LeavletControl>
  );
};

export default InfoModalButton;
