import styled from "styled-components";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Footer } from "../components/Footer.tsx";
import { theme } from "../theme";
import { aboutMarkdown } from "../about.js";

const AboutContainer = styled.div`
  flex: 1;
  width: 60em;
  min-width: 60em;
  max-width: 60em;
  padding: 5em;
  background-color: white;
  color: black;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  /*overflow-y: scroll;*/

  h2 {
    font-size: 1.125rem;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
    color: #2364bb;
  }

  a:visited {
    text-decoration: underline;
    color: #8c5371;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  width: 100%;
  flex: 1;

  footer {
    a,
    a:visited {
      color: ${(props) => props.theme.offwhite};
      text-decoration: none;
      /*
      font-weight: bold;
      font-size: 20px;
			*/
    }
  }
`;

const RelatedResearchContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  height: fit-content;
  gap: 1rem;
`;

const RelatedResearchCard = styled(Card)`
	height: 256px;
  width: 256px;

  a, a:visited {
    height: 100%;
		width: 100%;
		color: black;
		text-decoration: none;
    padding: 1.5rem;
  }

	:hover, a:hover {
		background-color: ${props => props.theme.mid};
		color: ${(props) => props.theme.offwhite};
	}

`;

const CardTitle = styled(Card.Title)`
  font-weight: bold;
  font-size: 24px;
`;

const AboutAccordion = styled(Accordion)`
  margin-bottom: 2rem;
`;

const SectionHeader = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  width: 90%;
  margin-bottom: 1rem;
`;

export const AboutPage = () => {
  return (
    <PageContainer>
      <AboutContainer>
        <SectionHeader>About the Report</SectionHeader>
        <AboutAccordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Where can I download the data?</Accordion.Header>
            <Accordion.Body>
              You can find the data from our analyses on <a href="https://datacommon.mapc.org/" target="_blank" rel="noreferrer">DataCommon</a>:
              <br />
              <a href="https://datacommon.mapc.org/browser/datasets/482" target="_blank" rel="noreferrer">Homes for Profit - Speculative Investment Analysis (Municipality)</a>
              <br />
              <a href="https://datacommon.mapc.org/browser/datasets/484" target="_blank" rel="noreferrer">Homes for Profit - Speculative Investment Analysis (Census Tracts)</a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Who should I contact with questions?
            </Accordion.Header>
            <Accordion.Body>
              For questions, comments, or other inquiries, feel free to reach
              out to us at{" "}
              <a href="mailto:research@mapc.org">research@mapc.org</a>.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Contributors</Accordion.Header>
            <Accordion.Body>
              <h2>Research Authors and Analysts:</h2>
              <ul>
                <li>Jessie Partridge Guerrero, Interim Director of Data Services</li>
                <li>Alexa DeRosa, Regional Planning Data Analyst</li>
                <li>Dr. Seleeke Flingai, Research Analyst (former)</li>
                <li>Taylor Perez, Research Intern (former)</li>
                <li>Sarah Philbrick, Research Analyst (former)</li>
                <li>Tim Reardon, Director of Data Services (former)</li>
              </ul>

              <h2>Web design and development:</h2>
              <ul>
                <li>Jonathan McKenzie, Civic Web Developer</li>
                <li>Stephen Larrick, Digital Services Manager</li>
              </ul>

              <h2>Policy:</h2>
              <ul>
                <li>Leah Robins, Director of Government Affairs</li>
                <li>Matt Walsh, Policy Analyst</li>
                <li>Andrea Harris-Long, Manager of Housing and Neighborhood Development</li>
                <li>Alexis Smith, Principal Regional Housing and Land Use Planner</li>
              </ul>

              <h2>Communications and Media:</h2>
              <ul>
                <li>Tim Viall, Senior Communications Specialist</li>
                <li>Amanda Linehan, Communications Director</li>
                <li>David McDonald, Visual Designer</li>
                <li>Stephanie Higgins, Communications Specialist</li>
              </ul>

              <h2>Event support:</h2>
              <ul>
                <li>Eric Hove, Director of Strategic Initiatives </li>
                <li>Sasha Parodi, Community Engagement Manager and Subregional Program Manager </li>
              </ul>

              <h2>Leadership:</h2>
              <ul>
                <li>Marc Draisen, Executive Director</li>
                <li>Lizzi Weyant, Deputy Executive Director</li>
              </ul>

              This research was possible thanks to funding from the Barr Foundation.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Technical Documentation</Accordion.Header>
            <Accordion.Body>
              <p>
                To learn more about how these data were collected and analyzed to
                create this report and the map, you can read our technical
                documentation <a href="/documents/homes_for_profit_technical_doc.docx">here</a>.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Glossary</Accordion.Header>
            <Accordion.Body>
              <Markdown
                remarkPlugins={[remarkGfm]}
                options={{ skipHtml: true }}
              >
                {aboutMarkdown}
              </Markdown>
            </Accordion.Body>
          </Accordion.Item>
        </AboutAccordion>
        <SectionHeader>Related Research</SectionHeader>
        <RelatedResearchContainer>
					{/*
          <RelatedResearchCard>
            <Card.Body>
              <CardTitle>Card Title</CardTitle>
              <Card.Subtitle className="mb-2 text-muted">
                Card Subtitle
              </Card.Subtitle>
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Card.Link href="#">Card Link</Card.Link>
              <Card.Link href="#">Another Link</Card.Link>
            </Card.Body>
          </RelatedResearchCard>
					*/}
          <RelatedResearchCard>
						<a href="https://metrocommon.mapc.org/action-areas/housing">
							<Card.Body>
								<CardTitle>Metrocommon x 2050</CardTitle>
								{/*
								<Card.Subtitle className="mb-2 text-muted">
									Action Area: Housing
								</Card.Subtitle>
								<Card.Text>TODO: Blurb on this content</Card.Text>
								<Card.Link href="https://metrocommon.mapc.org/action-areas/housing">
									View on MetroCommon
								</Card.Link>
								*/}
							</Card.Body>
						</a>
          </RelatedResearchCard>
          <RelatedResearchCard>
            <a href="https://housing-submarkets.mapc.org/">
							<Card.Body>
								<CardTitle>Housing Submarkets</CardTitle>
								{/*
								<Card.Subtitle className="mb-2 text-muted">
									Housing Policy, Data, and Interactive Map
								</Card.Subtitle>
								<Card.Text>
									Housing submarkets are collections of neighborhoods with similar
									housing stock and housing market characteristics. These
									characteristics determine who can find, afford, and remain in
									suitable housing in those neighborhoods.
								</Card.Text>
								<Card.Link href="https://housing-submarkets.mapc.org/">
									View the Housing Submarkets Website
								</Card.Link>
								*/}
							</Card.Body>
						</a>
          </RelatedResearchCard>
        </RelatedResearchContainer>
      </AboutContainer>
      <Footer
        backgroundColor={theme.mid}
        fontColor={"#FFFFFF"}
        paddingLeft={50}
        paddingRight={50}
        maxWidth={1366}
      />
    </PageContainer>
  );
};

export default AboutPage;
