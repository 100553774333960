import styled from "styled-components";
import { darken, desaturate } from "polished";
import Report from "../components/Report";

const ReportWrapper = styled.div`
  width: 60em;
  min-width: 60em;
  max-width: 60em;
  padding: 5em;
  background-color: white;
  color: black;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  overflow-y: scroll;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Calibre", sans-serif;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
    font-weight: bold;
  }

  /* Report links */
  a {
    text-decoration: underline;
    /* color: ${(props) => props.theme.accentPrimary}; */
    color: #2364bb;
  }

  a:visited {
    text-decoration: underline;
    color: #8c5371;
    /*
    color: ${(props) =>
      darken(0.25, desaturate(0.25, props.theme.accentPrimary))};
      */
  }

  /* Pseudo-lists (defining terms) */
  blockquote {
    margin-left: 3rem;
  }

  p > img {
    display:block;
    margin-left: auto;
    margin-right: auto;
  }

  /* Image captions */
  p > img + span {
    padding: 1rem 3rem;
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  table {
    border-color: black !important;
  }

  /* Table headers */
  table > thead > tr > th {
    background-color: black;
    color: white;
    /* font-family: "Swift Neue", serif;*/
  }

  table.table:nth-child(3 of table.table) > thead > tr:nth-child(1) > th {
    overflow-x: visible;
    white-space: nowrap;
    max-width: 7rem;
  }

  table.table:nth-child(3 of table.table) > tbody > tr:nth-child(1) > td {
    background-color: black;
    color: white;
    /* font-family: "Swift Neue", serif;*/
    width: fit-content;
    min-width: fit-content;
    white-space: nowrap;
    font-weight: bold;
  }

  table.table:nth-child(3 of table.table) > tbody > tr:nth-child(1) > td:nth-child(3) {
  }

  /* Table captions */
  table + p > span {
    padding: 0rem 3rem;
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  ul li p {
    margin-bottom: 0px;
  }

  /* Slides */
  iframe {
    height: 600px;
  }
`;

export const ReportPage = () => {
  return (
    <ReportWrapper>
      <Report />
    </ReportWrapper>
  );
};

export default ReportPage;
