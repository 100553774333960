import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import * as echarts from "echarts";
// TODO: Go back to deep imports for tree-shaking
// Could not figure out how to make axis pointer work =[
/*
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  AxisPointerComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  AxisPointerComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

*/
const ChartContainer = styled.div`
  padding: 0rem;
  border-top: 1px solid ${(props) => props.theme.dark};
  height: 380px;
`;

const Chart = styled.div`
  height: 380px;
  width: 100%;
`;

const categories = [
  {
    label: "Single Family",
    key: "sf_inv_p",
  },
  {
    label: "Two-Family",
    key: "r2f_inv_p",
  },
  {
    label: "Three-Family",
    key: "r3f_inv_p",
  },
  {
    label: "Condominium",
    key: "con_inv_p",
  },
];

const baseOption = {
  title: {
    text: "Share of investor transactions by housing type",
    subtext: "(for selected census tract)",
    subtextStyle: {
      fontStyle: "italic",
      height: "50",
      verticalAlign: "top",
    },
    textStyle: {
      fontSize: 14,
    },
    overflow: "break",
    top: 15,
  },
  color: [theme.mid],
  /*
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  */
  grid: {
    show: true,
    top: 75,
    bottom: 75,
    containLabel: true,
    backgroundColor: "#ffffff",
  },
  xAxis: {
    type: "category",
    axisLabel: { interval: 0, rotate: 30, fontSize: 10, margin: 10 },
    axisTick: {
      alignWithLabel: true,
    },
    name: "Housing type",
    nameLocation: "center",
    nameGap: "50",
    nameTextStyle: {
      fontSize: 12,
      fontWeight: "bold",
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: (value) =>
        `${Number(value).toLocaleString(undefined, {
          style: "percent",
          maximumFractionDigits: 1,
        })}`,
      fontSize: 10,
    },
    name: "Share of transactions\nby housing type",
    nameLocation: "center",
    nameGap: "35",
    nameTextStyle: {
      fontSize: 12,
      fontWeight: "bold",
    },
    max: 1,
    // max: (value) => value.max > 0.8 ? 1 : Math.round((value.max + 0.15) * 10) / 10,
  },
  series: [
    {
      type: "bar",
      name: "Housing type",
      label: {
        show: true,
        offset: [0, -15],
        formatter: (s) =>
          `${Number(s.data).toLocaleString(undefined, {
            style: "percent",
            maximumFractionDigits: 1,
          })}`,
      },
    },
  ],
};

const tractToChartOption = (tract) => {
  if (tract == null) return {};

  const chartOption = { ...baseOption };
  chartOption.xAxis.data = [];
  chartOption.series[0].data = [];
  categories.forEach((cat) => {
    chartOption.xAxis.data.push(cat.label);
    chartOption.series[0].data.push(tract[cat.key] || 0);
  });
  chartOption.title.subtext = `(for selected census tract: ${tract.geoid})`;

  return chartOption;
};

export const HousingBarChart = ({ tract }) => {
  const chartRef = useRef(null);
  const option = tractToChartOption(tract);
  const chartProps = {
    name: "Share of investor transactions by housing type",
  };
  const loading = false;

  useEffect(() => {
    // Initialize chart
    let chart;
    if (chartRef.current !== null) {
      chart = echarts.init(chartRef.current);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current);
      chart.setOption(option);
    }
  }, [option]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current);
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading]);

  return (
    <ChartContainer>
      <Chart ref={chartRef} {...chartProps}>
        TODO: alt text for chart
      </Chart>
    </ChartContainer>
  );
};

export default HousingBarChart;
